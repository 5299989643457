<div class="modal-header">
  <h2>{{ 'USER_PREFERENCE.TITLE_MODAL_ENABLE_DISPLAY' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3" [formGroup]="unlockForm">
  <label class="custom-label"
    >{{ 'USER_PREFERENCE.MODAL_ENABLE_DISPLAY_PIN' | translate }}
    <input
      type="password"
      class="custom-input"
      [placeholder]="'USER_PREFERENCE.MODAL_ENABLE_DISPLAY_PIN' | translate"
      formControlName="pin"
    />
    <div
      *ngIf="errorMessage && (unlockForm.touched || unlockForm.dirty)"
      class="input-field-error"
    >
      {{ errorMessage }}
    </div>
  </label>
</div>
<div class="modal-footer">
  <button class="button-primary" (click)="enable()">
    {{ 'GLOBAL.SEND' | translate }}
  </button>
</div>
