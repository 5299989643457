<h2 class="mt-3">
  <span>{{ 'NAVIGATION.LAINOX_RECIPES' | translate }}</span>
</h2>
<div id="recipe-list">
  <ng-container *ngFor="let r of recipes.currentItems">
    <a [routerLink]="['/', 'recipes', r.id]">
      <app-recipe-box [recipe]="r"></app-recipe-box>
    </a>
  </ng-container>
  <span *ngIf="recipes.currentItems.length === 0">{{
    'DASHBOARD.RECIPES.NO_RECIPES' | translate
  }}</span>
</div>
<div class="text-right my-3">
  <a [routerLink]="['/', 'recipes']" class="link-primary"
    >{{ 'LAINOX_RECIPES.VIEW_ALL' | translate }}
    <i class="fa fa-fw fa-angle-right"></i
  ></a>
</div>
