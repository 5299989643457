import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unlock-display-multi',
  templateUrl: './unlock-display-multi.component.html',
  styleUrls: ['./unlock-display-multi.component.scss']
})
export class UnlockDisplayMultiComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, private ts: TranslateService) {}

  @Output() correctPin = new EventEmitter();
  unlockForm: UntypedFormGroup;
  errorMessage: string = "";

  ngOnInit(): void {
    this.unlockForm = new UntypedFormGroup({
      pin: new UntypedFormControl('', [Validators.required])
    });

    this.unlockForm.valueChanges.subscribe((v) => {
      this.errorMessage = "";
    })
  }

  enable() {
    if (this.validatePin(this.unlockForm.value)) {
      this.correctPin.emit(this.unlockForm.value);
      this.activeModal.close();
    } else {
      this.errorMessage = this.ts.instant("USER_PREFERENCE.MODAL_ENABLE_INVALID_PIN");
    }
  }

  validatePin(pinControl: UntypedFormControl) {
    let CORRECT_PIN = '0000';
    return pinControl.value === CORRECT_PIN ? true : false;
  }
}
