import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeycloakAngularModule } from 'keycloak-angular';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ApiModule, BASE_PATH } from './api/nbk';
import { appInit } from './app-init';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './core/auth.service';
import { DictionaryService } from './core/dictionary.service';
import { HttpErrorInterceptor } from './core/http-error.interceptor';
import { MykeycloakService } from './core/mykeycloak.service';
import { CountersComponent } from './dashboard/counters/counters.component';
import { DashboardDevicesComponent } from './dashboard/dashboard-devices/dashboard-devices.component';
import { DashboardMenusComponent } from './dashboard/dashboard-menus/dashboard-menus.component';
import { DashboardRecipesComponent } from './dashboard/dashboard-recipes/dashboard-recipes.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { LanguageBoxComponent } from './layout/navigation/language-box/language-box.component';
import { UserBoxComponent } from './layout/navigation/user-box/user-box.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { UserPreferenceComponent } from './user/user-preference/user-preference.component';
import { SharedModule } from './shared/shared.module';
import { UnlockDisplayMultiComponent } from './user/user-preference/unlock-display-multi/unlock-display-multi.component';
import { CompanyComponent } from './user/user-detail/company/company.component';
import { PersonalInformationComponent } from './user/user-detail/personal-information/personal-information.component';
import { SectorComponent } from './user/user-detail/sector/sector.component';
import { OvenComponent } from './user/user-detail/oven/oven.component';
import { MyDisplayComponent } from './my-display/my-display.component';
import { MyDisplayListComponent } from './my-display/my-display-list/my-display-list.component';
import { MyDisplayFiltersComponent } from './my-display/my-display-filters/my-display-filters.component';
import { DisplayOracleComponent } from './my-display/displays/display-oracle/display-oracle.component';
import { DisplayNabookComponent } from './my-display/displays/display-nabook/display-nabook.component';
import { DisplayActionsComponent } from './my-display/display-actions/display-actions.component';
import { DisplayRecipesComponent } from './my-display/display-recipes/display-recipes.component';
import { DndModule } from 'ngx-drag-drop';
import { UserRolesComponent } from './user/user-roles/user-roles.component';
import { ServiceUserInfo } from './user/service-user/service-user-info/service-user-info.component';
import { ServiceUserComponent } from './user/service-user/service-user.component';
import { ServiceDeviceAssociationComponent } from './user/service-user/device-association/service-device-association.component';
import { CreateUserModalComponent } from './user/create-user-modal/create-user-modal.component';
import { CounterComponent } from './dashboard/counters/counter/counter.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';
import { AccessoriesComponent } from './accessories/accessories.component';
import { DevicesComponent } from './devices/devices.component';
import { HamburgerComponent } from './layout/navigation/hamburger/hamburger.component';
import { StoreModule } from '@ngrx/store';
import { sidebarReducer } from './store/sidebar/sidebar.reducer';

@NgModule({ 
    declarations: [
        AppComponent,
        LayoutComponent,
        NavigationComponent,
        FooterComponent,
        DashboardComponent,
        CountersComponent,
        DashboardDevicesComponent,
        DashboardRecipesComponent,
        DashboardMenusComponent,
        LanguageBoxComponent,
        UserBoxComponent,
        UserDetailComponent,
        UserPreferenceComponent,
        UnlockDisplayMultiComponent,
        CompanyComponent,
        PersonalInformationComponent,
        SectorComponent,
        OvenComponent,
        MyDisplayComponent,
        MyDisplayListComponent,
        MyDisplayFiltersComponent,
        DisplayOracleComponent,
        DisplayNabookComponent,
        DisplayActionsComponent,
        DisplayRecipesComponent,
        UserRolesComponent,
        ServiceUserComponent,
        ServiceUserInfo,
        ServiceDeviceAssociationComponent,
        CreateUserModalComponent,
        CounterComponent,
        PrivacyComponent,
        CookiesComponent,
        AccessoriesComponent,
        DevicesComponent,
        HamburgerComponent,
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot(),
        KeycloakAngularModule,
        ApiModule,
        SharedModule,
        NgProgressModule.withConfig({
            color: '#1c3865',
            spinner: false
        }),
        NgProgressHttpModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            titleClass: 'toast-title',
            messageClass: 'toast-message'
        }),
        DndModule,
        StoreModule.forRoot({ sidebar: sidebarReducer })
    ], 
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            multi: true,
            deps: [DictionaryService, MykeycloakService, AuthService]
        },
        {
            provide: BASE_PATH,
            useValue: environment.basePath
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
