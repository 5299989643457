<div class="counter-box">
  <a
    class="menu-link"
    [routerLink]="[routerLink]"
    [queryParams]="queryParams"
    routerLinkActive="active"
  >
    <div class="counter-value">
      <span>{{ total }}</span> <i class="fa fa-fw fa-utensils"></i>
    </div>
    <div class="counter-text">{{ text }}</div>
  </a>
</div>
