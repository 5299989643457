import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardResolver } from './dashboard/dashboard.resolver';
import { LayoutComponent } from './layout/layout.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { UserPreferenceComponent } from './user/user-preference/user-preference.component';
import { UserRolesComponent } from './user/user-roles/user-roles.component';
import { ServiceUserComponent } from './user/service-user/service-user.component';
import { UserDetailResolver } from './user/user-detail/user-detail.resolver';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';
import { AccessoriesComponent } from './accessories/accessories.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'user-profile',
        component: UserDetailComponent,
        resolve: {
          userLogged: UserDetailResolver
        },
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        }
      },
      {
        path: 'user-settings',
        canActivate: [AuthGuard],
        component: UserPreferenceComponent,
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        }
      },
      {
        path: 'user-roles',
        canActivate: [AuthGuard],
        component: UserRolesComponent,
        data: {
          permission: ['USER_MANAGER', 'SUPERUSER']
        }
      },
      {
        path: 'service-users',
        canActivate: [AuthGuard],
        component: ServiceUserComponent,
        data: {
          permission: ['SERVICE']
        }
      },
      {
        path: 'accessories',
        canActivate: [AuthGuard],
        component: AccessoriesComponent,
        data: {
          permission: ['CHEF_LAINOX', 'SUPERUSER']
        }
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent,
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        },
        resolve: {
          dashboardData: DashboardResolver
        }
      },
      {
        path: 'my-display',
        canActivate: [AuthGuard],
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        },
        loadChildren: () =>
          import('./my-display/my-display-routing.module').then((m) => m.MyDisplayRoutingModule)
      },
      {
        path: 'recipes',
        canLoad: [AuthGuard],
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        },
        loadChildren: () =>
          import('./recipes/recipes.module').then((m) => m.RecipesModule)
      },
      {
        path: 'my-naboo',
        canLoad: [AuthGuard],
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        },
        loadChildren: () =>
          import('./my-naboo/my-naboo.module').then((m) => m.MyNabooModule)
      },
      {
        path: 'menu',
        canLoad: [AuthGuard],
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        },
        loadChildren: () =>
          import('./menu/menu.module').then((m) => m.MenuModule)
      },
      {
        path: 'ingredients',
        canActivate: [AuthGuard],
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER']
        },
        loadChildren: () =>
          import('./ingredients/ingredients.module').then((m) => m.IngredientsModule)
      },
      {
        path: 'devices',
        canActivate: [AuthGuard],
        data: {
          permission: ['CHEF_LAINOX', 'CHEF_EXECUTIVE', 'SUPERUSER', 'SERVICE']
        },
        loadChildren: () =>
          import('./devices/devices.module').then((m) => m.DevicesModule)
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'cookies',
    component: CookiesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
