import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { MykeycloakService } from 'src/app/core/mykeycloak.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit {
  userInfoIsOpen = false;

  userMenuItems = [
    {
      condition: true,
      routeLink: ['/user-profile'],
      iconClass: 'fa-user',
      labelKey: 'NAVIGATION.PROFILE'
    },
    {
      condition: !this.authService.permissions.USER_MANAGER && !this.authService.permissions.SERVICE,
      routeLink: ['/user-settings'],
      iconClass: 'fa-sliders-h',
      labelKey: 'NAVIGATION.SETTINGS'
    },
    {
      condition: this.authService.permissions.USER_MANAGER || this.authService.permissions.SUPERUSER,
      routeLink: ['/user-roles'],
      iconClass: 'fa-user-tag',
      labelKey: 'NAVIGATION.ROLES'
    },
  ]

  constructor(public mkc: MykeycloakService, public authService: AuthService) {}

  ngOnInit(): void {}

  toggleInfo(): void {
    this.userInfoIsOpen = !this.userInfoIsOpen;
  }

}
