<app-navigation></app-navigation>
<main #main [dir]="direction | async">
  <ng-progress [thick]="true"></ng-progress>
  <div #blockMenuResponsive class="block-menu-responsive"></div>
  <div class="mb-4">
    <app-hamburger></app-hamburger>
  </div>
  <app-root></app-root>
  <app-footer></app-footer>
</main>
