<div class="row mt-2">
  <div class="col-12">
    <app-datatable-paginated
      [dataSource]="dataSource"
      [columns]="columns"
      [className]="'fix-height'"
    ></app-datatable-paginated>
  </div>
</div>

<ng-template #actionTpl let-row="row" let-rowIndex="rowIndex">
  <i
    *ngIf="!this.isEditable[rowIndex]"
    (click)="toggleEdit(row, rowIndex)"
    class="table-icons fa fa-fw fa-pen"
  ></i>
  <i
    *ngIf="!this.isEditable[rowIndex]"
    class="table-icons fa fa-fw fa-trash-alt"
    (click)="deleteRow(row)"
  ></i>

  <i
    class="table-icons fa fa-fw fa-save"
    *ngIf="this.isEditable[rowIndex]"
    title="Save"
    (click)="editRow(row, rowIndex)"
  ></i>
  <i
    class="table-icons fa fa-fw fa-times"
    *ngIf="this.isEditable[rowIndex]"
    title="Delete"
    (click)="this.isEditable[rowIndex] = !this.isEditable[rowIndex]"
  ></i>
</ng-template>

<ng-template #dateTpl let-row="row" let-rowIndex="rowIndex">
  <div *ngIf="!this.isEditable[rowIndex]">
    <span>{{ row.from | fromUTCToLocale | date: 'short':timezone:lang }}</span>
    <span> - </span>
    <span>{{ row.to ? (row.to | fromUTCToLocale | date: 'short':timezone:lang) : "" }}</span>
  </div>
  <div *ngIf="this.isEditable[rowIndex]" class="row input-group">
    <div class="row mb-2 mt-2 gap-3" [formGroup]="dateRangeForm">
      <div>
        <label>{{ 'DEVICE.TELEMETRY.FROM' | translate }}</label>
        <app-date-time-picker 
          formControlName="dateFrom" 
          name="dateFrom"
          [seconds]="false"
        ></app-date-time-picker> 
      </div>
      <div>
        <label>{{ 'DEVICE.TELEMETRY.TO' | translate }}</label>  
        <app-date-time-picker 
          formControlName="dateTo" 
          name="dateTo"
          [seconds]="false"
          [clearable]="true"
        ></app-date-time-picker>   
      </div>
      <app-control-errors
        class="form-group-error"
        controlName="dateRangeForm"
      ></app-control-errors>
    </div>
  </div>
</ng-template>

