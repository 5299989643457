import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { of, Subject } from 'rxjs';
import { retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccessoryBoDtoNBK, AccessoryDeviceModelDtoNBK, AccessoryService } from '../api/nbk';
import { getDeviceModelList, KEY_LANGUAGE_STORAGE, SelectableDeviceModel } from '../core/utils';
import { ModalService } from '../shared/modals/modal.service';
import { TwoBtnModalState } from '../shared/modals/two-btn-modal/two-btn-modal.component';
import { CreateAccessoryModalComponent } from '../shared/recipe-detail/accessories/create-accessory-modal/create-accessory-modal.component';
import { SelectableItem } from '../core/device.utils';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent implements OnInit, OnDestroy {
  dataSource: ODataFiltrableDataSource<AccessoryBoDtoNBK> =
    new ODataFiltrableDataSource();
  columns: TableColumn[] = [];

  loadAccessories$: Subject<void> = new Subject();
  unsubscribe$: Subject<void> = new Subject();
  delete$: Subject<AccessoryBoDtoNBK> = new Subject();

  @ViewChild('actionTpl', { static: true }) actionTpl: ElementRef<HTMLElement>;
  @ViewChild('imageTpl', { static: true }) imageTpl: ElementRef<HTMLElement>;

  constructor(
    private accessoriesService: AccessoryService,
    private ts: TranslateService,
    private modalService: NgbModal,
    private modalButtonService: ModalService,
    private toastrService: ToastrService
  ) {
    this.dataSource.fetchFunction = (top, skip, count, _orderBy, filter) => {
      return this.accessoriesService.getAllAccessories(
        localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
        top,
        skip,
        count,
        _orderBy,
        filter
      );
    };
  }

  ngOnInit(): void {
    this.loadAccessories$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.dataSource.loadData())
      )
      .subscribe();

    this.delete$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((res: AccessoryBoDtoNBK) =>
          this.accessoriesService.deleteAccessory(res.id!)
        ),
        tap(() => {
          this.loadDataSource();
          this.toastrService.success(
            this.ts.instant('ACCESSORY.ACCESSORY_DELETED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        }),
        retry()
      )
      .subscribe();

    this.loadDataSource();

    this.columns = [
      {
        prop: 'name',
        name: this.ts.instant('ACCESSORIES.GRID.NAME')
      },
      {
        prop: 'model',
        name: this.ts.instant('DEVICES.MODEL'),
        $$valueGetter: (e, prop) => 
          e.deviceModels.map( (a: AccessoryDeviceModelDtoNBK) => this.ts.instant(`DEVICE_MODEL.${a.deviceModel}`) ).join(', ')
      },
      {
        prop: 'description',
        name: this.ts.instant('ACCESSORIES.GRID.DESCRIPTION')
      },
      {
        name: this.ts.instant('ACCESSORIES.GRID.IMAGE'),
        cellTemplate: this.imageTpl
      },
      {
        prop: '',
        cellTemplate: this.actionTpl,
        maxWidth: 100
      }
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
    this.unsubscribe$.next();
  }

  editAccessory(accessory: AccessoryBoDtoNBK, index: number) {
    const modalRef = this.modalService.open(CreateAccessoryModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.accessory = accessory;
    modalRef.componentInstance.accessoryUpdated.subscribe((v: UntypedFormGroup) => {
      this.toastrService.success(
        this.ts.instant('ACCESSORY.ACCESSORY_UPDATED'),
        this.ts.instant('GLOBAL.SUCCESS')
      );
      this.loadDataSource();
    });
  }

  createAccessory() {
    const modalRef = this.modalService.open(CreateAccessoryModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.isCreate = true;
    modalRef.componentInstance.accessoryCreated.subscribe((v: UntypedFormGroup) => {
      this.toastrService.success(
        this.ts.instant('ACCESSORY.ACCESSORY_CREATED'),
        this.ts.instant('GLOBAL.SUCCESS')
      );
      this.loadDataSource();
    });
  }

  deleteAccessory(accessory: AccessoryBoDtoNBK, index: number) {
    const modalInfo: TwoBtnModalState = {
      title: this.ts.instant('ACCESSORY.MODAL.DELETE_TITLE'),
      message: this.ts.instant('ACCESSORY.MODAL.DELETE_MESSAGE'),
      confirmBtnClass: 'button-danger',
      confirmBtnText: this.ts.instant('ACCESSORY.MODAL.DELETE_CONFIRM'),
      cancelBtnClass: 'button-secondary',
      cancelBtnText: this.ts.instant('ACCESSORY.MODAL.DELETE_ABORT'),
      confirm$: of({})
    };

    this.modalButtonService.showTwoBtnModal(modalInfo).subscribe((r: any) => {
      if (r) {
        this.delete$.next(accessory);
      }
    });
  }

  private loadDataSource() {
    this.loadAccessories$.next();
  }
  
}
