import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-oven',
  templateUrl: './oven.component.html',
  styleUrls: ['./oven.component.scss']
})
export class OvenComponent implements OnInit, AfterViewInit {
  @Input() controlValue: UntypedFormGroup;
  ovenYearValue!: number;
  years: any[] = [];
  brandItems = [
    { key: 'Lainox', value: 'Lainox' },
    { key: 'Rational', value: 'Rational' },
    { key: 'Electrolux', value: 'Electrolux' },
    { key: 'Convotherm', value: 'Convotherm' },
    { key: 'MKN', value: 'MKN' },
    { key: 'Unox', value: 'Unox' },
    { key: 'Eloma', value: 'Eloma' },
    { key: 'Other', value: 'Other' },
    { key: 'None', value: 'None' }
  ];
  typeItems = [
    { key: 'Combi Steam', value: 'Combi Steam' },
    { key: 'Microwave', value: 'Microwave' },
    { key: 'Convection', value: 'Convection' },
    { key: 'Static', value: 'Static' },
    { key: 'Steamer', value: 'Steamer' }
  ];

  constructor() {
    this.setYears(new Date().getFullYear());
  }

  ngAfterViewInit(): void {
    this.controlValue.get('ovenYear')?.valueChanges.subscribe( x => {
      this.changeOvenYearValue(x);
    })
  }

  ngOnInit(): void {
    let x = this.controlValue.value.ovenYear;
    this.changeOvenYearValue(x);
  }

  private changeOvenYearValue(x: any) {
    if (x) {
      this.setYears(+x);
      this.ovenYearValue = +x;
    }
  }

  setYears(currentYear: number){
    this.years = [];
    for( let i = currentYear - 25; i < currentYear + 25 ; i++ ){
      this.years.push(i);
      if( new Date().getFullYear() === i ) break;
    }
  }
  
}
