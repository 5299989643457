import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardBoDtoNBK, RecipeService } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { isSuperuserOrChefLainox } from 'src/app/core/permission.utils';
import { RecipeNameModalComponent } from 'src/app/shared/recipe-name-modal/recipe-name-modal.component';

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss']
})
export class CountersComponent implements OnInit {
  @Input() counters: DashboardBoDtoNBK;

  constructor(
    private modalService: NgbModal,
    private recipeService: RecipeService,
    private ts: TranslateService,
    private toastrService: ToastrService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setDefaultValues();
  }

  createNewRecipe() {
    const modalRef = this.modalService.open(RecipeNameModalComponent, {
      modalDialogClass: 'modal-sm'
    });
  }

  uploadNewRecipe(event: any) {
    const file: File = event.target.files[0];
    this.recipeService.importRecipe(file).subscribe(() => {
      this.toastrService.success(
        this.ts.instant('RECIPE.IMPORTED'),
        this.ts.instant('GLOBAL.SUCCESS')
      );
    });
  }

  private setDefaultValues() {
    const setKeyValue =
      <U extends keyof T, T extends object>(key: U) =>
      (obj: T, value: any) =>
        (obj[key] = value);

    for (const [key, value] of Object.entries(this.counters)) {
      if (!value) {
        setKeyValue<keyof DashboardBoDtoNBK, DashboardBoDtoNBK>(key as any)(
          this.counters,
          0
        );
      }
    }
  }

  canHandle() {
    return isSuperuserOrChefLainox(this.authService);
  }

}
