import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from 'src/app/core/dictionary.service';
import { KEY_LANGUAGE_STORAGE, languagesList } from 'src/app/core/utils';

@Component({
  selector: 'app-language-box',
  templateUrl: './language-box.component.html',
  styleUrls: ['./language-box.component.scss']
})
export class LanguageBoxComponent implements OnInit {
  language: UntypedFormControl = new UntypedFormControl('');
  languages = languagesList;

  constructor(private ds: DictionaryService, private ts: TranslateService) {}

  ngOnInit(): void {
    this.ds.lang$.subscribe((res) => {
      this.language.setValue(res);
    });
  }

  onLanguageChange(e: any): void {
    localStorage.setItem(KEY_LANGUAGE_STORAGE, e.target.value);
    location.reload();
  }
}
