// store/sidebar/sidebar.selectors.ts
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SidebarState } from './sidebar.reducer';

export const selectSidebarState = createFeatureSelector<SidebarState>('sidebar');

export const selectIsSidebarByCode = createSelector(
  selectSidebarState,
  (state: SidebarState) => state.byCode
);
