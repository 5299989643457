<div class="modal-header">
  <h2>{{ 'USER.CREATE_USER' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-3">

  <app-service-user-info 
    [controlValue]="userFormGroup" 
    [isNew]="true "
    [roles]="roles"
  ></app-service-user-info>

</div>

<div class="modal-footer">
  <button
    (click)="createNewUser()"
    class="button-primary"
  >
    {{ 'GLOBAL.CREATE' | translate }}
  </button>
</div>
