import { Component, OnInit } from '@angular/core';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { take } from 'rxjs/operators';
import { RecipeBoDtoNBK, RecipeService } from 'src/app/api/nbk';
import { KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';

@Component({
  selector: 'app-dashboard-recipes',
  templateUrl: './dashboard-recipes.component.html',
  styleUrls: ['./dashboard-recipes.component.scss']
})
export class DashboardRecipesComponent implements OnInit {
  recipes: ODataFiltrableDataSource<RecipeBoDtoNBK> =
    new ODataFiltrableDataSource<RecipeBoDtoNBK>();

  constructor(private recipeService: RecipeService) {}

  ngOnInit(): void {
    this.recipes.fetchFunction = (top, skip, count, _orderBy, filter) => {
      return this.recipeService
        .getAllRecipes(
          localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
          top,
          skip,
          count,
          _orderBy,
          'ics eq true'
        )
        .pipe(take(1));
    };
    this.recipes.loadData();
  }
}
