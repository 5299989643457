import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import * as PlotlyJS from 'plotly.js';
import { languagesList } from './core/utils';
import { PlotlyModule } from 'angular-plotly.js';

// PlotlyModule.plotlyjs = PlotlyJS;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'lainox-nabook';
  
  constructor(private ts: TranslateService) {
    ts.instant('DEVICE_MODEL.ORACLE')
    ts.instant('DEVICE_MODEL.NABOO')
    ts.instant('COOKING_MODE.MULTILEVEL')
    ts.instant('COOKING_MODE.SINGLE_LEVEL')
    ts.instant('COOKING_MODE.COMBI')
    ts.instant('COOKING_MODE.HSO')
  }
  
  ngOnInit(): void {
    // languagesList.forEach( async e => {
    //   var module = await import(`plotly.js-locales/${e.lang}`);
    //   PlotlyJS.register(module);
    // })
  }

}
