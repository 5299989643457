<div id="user-box" [ngClass]="{ open: userInfoIsOpen }">
  <div 
    class="user-name-logged pointer-element"
    (click)="toggleInfo()"
  >
    <span class="name-span">
      {{ mkc.user.firstName }} {{ mkc.user.lastName }}
    </span>
    <i
      class="fa"
      [ngClass]="userInfoIsOpen ? 'fa-angle-up' : 'fa-angle-down'"
    ></i>
  </div>
  <ul id="user-menu" *ngIf="userInfoIsOpen">
    <ng-container *ngFor="let item of userMenuItems">
      <li class="user-menu-item" id="user-profile" *ngIf="item.condition">
        <a
          [routerLink]="item.routeLink"
          [routerLinkActive]="'active'"
          href="javascript:void(0)"
        >
          <i class="fa fa-fw" [ngClass]="item.iconClass"></i>
          <span>{{ item.labelKey | translate }}</span>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
