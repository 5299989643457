import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { NavigationService } from '../navigation.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {

  @ViewChild('menuHamburger', { static: false }) hamburger: ElementRef;
  
  unsubscribe$: Subject<void> = new Subject();

  constructor(public ns: NavigationService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.ns.toggleHambuger$
    .pipe(
      takeUntil(this.unsubscribe$),
      tap((res) => {
        this.showHideHambuger(res);
      })
    )
    .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openNavbarResponsive() {
    this.ns.toggleNavbar$.next(true);
  }

  showHideHambuger(value: boolean) {
    if ( value ) {
      this.renderer.removeClass(this.hamburger.nativeElement, 'menu-hamburger');
    } else {
      this.renderer.addClass(this.hamburger.nativeElement, 'menu-hamburger');
    }
  }

}
