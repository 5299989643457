<h2 class="mt-3">
  <span>{{ "MENU.TITLE" | translate }}</span>
</h2>
<div id="menu-list">
  <app-menu
    *ngFor="let m of menus.currentItems"
    [menu]="m"
  ></app-menu>
  <span *ngIf="menus.currentItems.length === 0">{{ "DASHBOARD.MENU_NO_MENUS" | translate}}</span>
</div>

<div class="text-right my-3">
  <a [routerLink]="['/', 'menu']" class="link-primary"
    >{{ "MENU.ALL_MENUS" | translate }} <i class="fa fa-fw fa-angle-right"></i
  ></a>
</div>
