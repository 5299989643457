import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  @Input() routerLink?: string;
  @Input() queryParams?: Params;
  @Input() total?: number = 0;
  @Input() text?: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}
