import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { UserKeycloakDtoNBK, UserService } from '../../api/nbk';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {
  dataSource = new ODataFiltrableDataSource<UserKeycloakDtoNBK>();
  columns: TableColumn[] = [];
  isEditable: any = {};
  fieldControl: UntypedFormControl = new UntypedFormControl('', []);
  fieldArray: UntypedFormArray = new UntypedFormArray([]);
  filterControl: UntypedFormControl = new UntypedFormControl('', []);
  roles: Array<UserKeycloakDtoNBK.RolesEnum> = [
    'CHEF_EXECUTIVE',
    'CHEF_LAINOX',
    'SUPERUSER',
    'USER_MANAGER',
    'SERVICE'
  ];

  @ViewChild('actionTpl', { static: true }) actionTpl: ElementRef<HTMLElement>;
  @ViewChild('rolesTpl', { static: true })
  rolesTpl: ElementRef<HTMLElement>;

  constructor(
    private ts: TranslateService,
    private toastrService: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'email',
        name: this.ts.instant('ROLES.EMAIL')
      },
      {
        prop: 'firstName',
        name: this.ts.instant('ROLES.NAME')
      },
      {
        prop: 'lastName',
        name: this.ts.instant('ROLES.LASTNAME')
      },
      {
        prop: 'companyName',
        name: this.ts.instant('ROLES.COMPANY')
      },
      {
        prop: 'role',
        name: this.ts.instant('ROLES.ROLE'),
        cellTemplate: this.rolesTpl
      },
      {
        prop: '',
        cellTemplate: this.actionTpl,
        maxWidth: 100
      }
    ];

    this.dataSource.fetchFunction = (
      top,
      skip,
      count,
      _orderBy,
      filter,
      search
    ) => {
      return this.userService.getAllUsers(
        top,
        skip,
        count,
        _orderBy,
        filter,
        search
      );
    };

    this.dataSource.loadData();
  }

  toggleEdit(user: UserKeycloakDtoNBK, rowIndex: number) {
    this.isEditable = {};
    this.isEditable[rowIndex] = !this.isEditable[rowIndex];
    this.fieldControl.setValue(this.getRole(user.roles!));
  }

  editUser(user: UserKeycloakDtoNBK, rowIndex: number) {
    if ( user.roles!.length > 1 ) {
      var roleIndex = this.roleIndex(user.roles!, this.getRole(user.roles!));
      user.roles![roleIndex] = this.fieldControl.value;
    } else {
      user.roles!.push(this.fieldControl.value);
    }
    this.isEditable[rowIndex] = !this.isEditable[rowIndex];

    this.userService
      .updateuserRoles(user.id!, user.roles)
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('ROLES.USER_UPDATED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }

  applyFilter() {
    this.dataSource.setSearch(this.filterControl.value);
    this.dataSource.loadData();
  }

  cleanFilter() {
    this.filterControl.reset();
    this.dataSource.removeSeach();
    this.dataSource.loadData();
  }

  private roleIndex (roles: Array<UserKeycloakDtoNBK.RolesEnum>, find: UserKeycloakDtoNBK.RolesEnum) {
    return roles.findIndex( e => e === find);
  }

  getRole(roles: Array<UserKeycloakDtoNBK.RolesEnum>) {
    return roles.length === 1 ? roles[0] : roles.filter((el) => el != 'DEFAULT_ROLE')[0];
  }
  
}
