
<div [formGroup]="controlValue">
  <div class="row">
    <div class="col-12 col-md-6">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.NAME' | translate }}
        <input
          type="text"
          class="custom-input"
          formControlName="firstName"
        />
        <app-control-errors
          id="validate-firstName"
          controlName="firstName"
        ></app-control-errors>
      </label>
    </div>
  
    <div class="col-12 col-md-6">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.SURNAME' | translate }}
        <input
          type="text"
          class="custom-input"
          formControlName="lastName"
        />
        <app-control-errors
        id="validate-lastName"
        controlName="lastName"
        ></app-control-errors>
      </label>
    </div>
  
    <div class="col-12 col-md-6">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.EMAIL' | translate }}
        <input
          [readonly]="!isNew"
          type="email"
          class="custom-input"
          [ngClass]="{'disabled-input': !isNew}"
          formControlName="email"
        />
        <app-control-errors
          id="validate-email"
          controlName="email"
        ></app-control-errors>
      </label>
    </div>
  
    <div class="col-12 col-md-6">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.NUMBER_PHONE' | translate }}
        <input
          oninput="validity.valid||(value='');"
          type="number"
          min="0"
          class="custom-input"
          formControlName="phoneNumber"
        />
        <app-control-errors
          id="validate-phoneNumber"
          controlName="phoneNumber"
        ></app-control-errors>
      </label>
    </div>

    <div class="col-12 col-md-6">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.COUNTRY' | translate }}
        <select
          class="custom-input"
          formControlName="country"
        >
          <option
            *ngFor="let country of countryItems"
            [ngValue]="country.id"
          >
            {{ country.value }}
          </option>
        </select>
        <app-control-errors
          id="validate-country"
          controlName="country"
        ></app-control-errors>
      </label>
    </div>
  
    <div class="col-12 col-md-6">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.LANGUAGE' | translate }}
        <select
          class="custom-input"
          formControlName="locale"
        >
          <option
            *ngFor="let locale of localeItems"
            [ngValue]="locale.lang"
          >
            {{ locale.label }}
          </option>
        </select>
        <app-control-errors
          id="validate-locale"
          controlName="locale"
        ></app-control-errors>
      </label>
    </div>
  
    <div class="col-12" *ngIf="roles">
      <label class="custom-label w-100">
        {{ 'ROLES.ROLE' | translate }}
        <select
          class="custom-input"
          formControlName="roles"
        >
          <option
            *ngFor="let role of roles"
            [ngValue]="role"
          >
            {{ role }}
          </option>
        </select>
        <app-control-errors
          id="validate-roles"
          controlName="roles"
        ></app-control-errors>
      </label>
    </div>

    <div class="col-12">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.COMPANY.COMPANY' | translate }}
        <input
          type="text"
          class="custom-input"
          formControlName="companyName"
        />
        <app-control-errors
          id="validate-company"
          controlName="company"
        ></app-control-errors>
      </label>
    </div>

    <div class="col-12 col-md-6" *ngIf="isNew">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.PASSWORD' | translate }}
        <div class="psw-wrap mb-3">
          <input 
            type="{{showPsw ? 'text' : 'password'}}"
            class="custom-input"
            formControlName="password"
          />
          <span class="eye-button">
            <i class="pointer fa" 
               [ngClass]="{ 'fa-eye': !showPsw, 'fa-eye-slash': showPsw }" 
               (click)="togglePassword(0)"></i>
          </span>
        </div>
        <app-control-errors
          id="validate-password"
          controlName="password"
        ></app-control-errors>
      </label>
    </div>
  
    <div class="col-12 col-md-6" *ngIf="isNew">
      <label class="custom-label w-100">
        {{ 'USER_DETAIL.PERSONAL_INFO.CONFIRM_PASSWORD' | translate }}
        <div class="psw-wrap mb-3">
          <input 
            type="{{showConfirmPsw ? 'text' : 'password'}}"
            class="custom-input"
            formControlName="confirmPassword"
          />
          <span class="eye-button">
            <i class="pointer fa" 
               [ngClass]="{ 'fa-eye': !showConfirmPsw, 'fa-eye-slash': showConfirmPsw }" 
               (click)="togglePassword(1)"></i>
          </span>
        </div>
        <app-control-errors
          id="validate-password"
          controlName="confirmPassword"
        ></app-control-errors>
      </label>
    </div>
  
    <app-control-errors
      class="form-group-error"
      controlName="userFormGroup"
    ></app-control-errors>
  </div>

</div>
