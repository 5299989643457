import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { UserService, UserKeycloakDtoNBK } from 'src/app/api/nbk';
import { MykeycloakService } from 'src/app/core/mykeycloak.service';

@Injectable({
  providedIn: 'root'
})
export class UserDetailResolver  {
  constructor(
    private myKeyCloackService: MykeycloakService,
    private userService: UserService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserKeycloakDtoNBK> | Observable<never> {
    return this.userService.getLoggedUser().pipe(
      take(1),
      catchError(() => {
        return EMPTY;
      })
    );
  }
}
