<h1>{{ 'NAVIGATION.PROFILE' | translate }}</h1>
<h2>
  {{
    'USER_DETAIL.WELCOME'
      | translate: { username: mkc.user.firstName + ' ' + mkc.user.lastName }
  }}
  <div class="button-box">
    <button class="button-primary">
      <a [href]="changePasswordUrl" target="_blank">
        <i class="fa fa-key"></i>
        {{ 'USER_DETAIL.BUTTONS.CHANGE_PASSWORD' | translate }}</a
      >
    </button>
    <button
      class="button-primary"
      (click)="onSaveDetails()"
      [disabled]="!checkCompleted()"
    >
      <i class="fa fa-save"></i>
      {{ 'USER_DETAIL.BUTTONS.SAVE' | translate }}
    </button>
  </div>
</h2>

<div *ngIf="!authService.permissions.SERVICE; else servieUserTpl">
  <div *ngIf="!checkCompleted()" id="not-completed-alert">
    {{ 'USER_DETAIL.NOT_COMPLETED' | translate }}
  </div>

  <app-company [controlValue]="companyFormGroup"></app-company>
  <app-personal-information
    [controlValue]="personalInformationFormGroup"
  ></app-personal-information>
  <app-sector [controlValue]="sectorFormGroup"></app-sector>
  <app-oven [controlValue]="ovenFormGroup"></app-oven>
  
  <div class="row my-4">
    <div class="col-12">
      <label class="custom-label">
        <input type="checkbox" [formControl]="agreePrivacy" />
        <a routerLink="/privacy" target="_blank">{{
          'USER_DETAIL.AGREE_PRIVACY' | translate
        }}</a>
      </label>
    </div>
  </div>
</div>
<ng-template #servieUserTpl>
  <div class="mb-4">
    <app-service-user-info [controlValue]="serviceUserInfoFormGroup"></app-service-user-info>
  </div>
</ng-template>
