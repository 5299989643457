<div>
  <h2>Privacy Policy</h2>
  <h3>Cookie Policy</h3>
  <p>Ultimo aggiornamento: ottobre 2017</p>
  <h3>Cosa sono i cookie</h3>
  <p>
    Il sito http://nabook.cloud fa uso di cookie. Come chiarito dal Garante
    Privacy nelle FAQ del dicembre 2012, reperibili su&nbsp;<a
      href="http://www.garanteprivacy.it/"
      target="_blank"
      rel="noopener"
      >www.garanteprivacy.it</a
    >, i cookie sono &ldquo;piccoli file di testo&rdquo; &ndash; formati da
    lettere e numeri - &ldquo;che i siti visitati dall'utente&nbsp;inviano al
    suo terminale (solitamente al browser), dove vengono memorizzati per essere
    poi ritrasmessi agli stessi siti alla successiva visita del medesimo
    utente&rdquo;. I cookie hanno la funzione di snellire l'analisi del traffico
    sul web o di segnalare quando un sito specifico o una parte di esso vengono
    visitati, di distinguere tra loro i visitatori per poter fornire contenuti
    personalizzati, ed aiutano gli amministratori a migliorare il sito e
    l&rsquo;esperienza di navigazione degli utenti stessi.
  </p>
  <p>
    Attraverso i cookie non possiamo accedere ad altre informazioni memorizzate
    sul vostro dispositivo, anche se &egrave; qui che i cookie vengono
    scaricati. I cookie non possono caricare codici di alcun tipo, veicolare
    virus o malware e non sono dannosi per il terminale dell&rsquo;utente.
  </p>
  <p>
    Di seguito &egrave; possibile trovare tutte le informazioni sui cookie
    installati attraverso questo sito, e le indicazioni necessarie su come
    gestire le proprie preferenze a riguardo.
  </p>
  <h3>Consenso dell&rsquo;utente</h3>
  <p>
    Collegandosi per la prima volta ad una qualunque pagina di nabook.cloud,
    l&rsquo;utente vedr&agrave; apparire una sintetica informativa
    sull&rsquo;utilizzo dei cookie. Chiudendo tale informativa tramite
    l&rsquo;apposito tasto o cliccando al di fuori del banner che la contiene e
    proseguendo nella navigazione, l&rsquo;utente acconsente al nostro utilizzo
    dei cookie, secondo le modalit&agrave; descritte nella presente Cookie
    Policy.
  </p>
  <p>
    Il sito ricorda la scelta effettuata dall&rsquo;utente, pertanto
    l&rsquo;informativa breve non verr&agrave; riproposta nei collegamenti
    successivi dallo stesso dispositivo. Tuttavia, l&rsquo;utente ha sempre la
    possibilit&agrave; di revocare in tutto o in parte il consenso gi&agrave;
    espresso.
  </p>
  <p>
    Qualora si riscontrassero problemi di natura tecnica legati alla prestazione
    del consenso, vi preghiamo di contattarci tramite gli appositi canali
    previsti da questo sito per consentici di prestarvi assistenza.
  </p>
  <h3>Quali tipi di cookie utilizziamo</h3>
  <p>
    <strong
      >L&rsquo;utilizzo di cookie da parte del Titolare di questo sito, Lainox
      Ali S.r.l., con sede in Vittorio Veneto (TV) alla via Schiaparelli 15, si
      inquadra nella Privacy Policy dello stesso; per tutte le informazioni
      richieste dall&rsquo;art. 13 Codice della Privacy </strong
    ><a routerLink="/privacy"><strong>clicca&nbsp;qui</strong></a
    ><strong>.</strong>
  </p>
  <p>
    <strong
      >Per consentire la fruizione del nostro sito e l&rsquo;erogazione dei
      nostri servizi, facciamo uso sia di cosiddetti cookie persistenti
      (cio&egrave; cookie che rimangono in memoria fino a quando non sono
      eliminati manualmente dall&rsquo;utente o per i quali &egrave; prevista
      una rimozione programmata a lungo termine) sia di cosiddetti cookie di
      sessione, che non sono memorizzati in modo persistente sul computer del
      visitatore e scompaiono con la chiusura del browser.</strong
    >
  </p>
  <p>
    <strong
      >Utilizziamo cookie di tipologie differenti - con specifiche funzioni -
      che possiamo cos&igrave; classificare:</strong
    >
  </p>
  <table width="736" cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <td colspan="2" width="736">
          <p>
            <strong
              >COOKIE DI PRIMA PARTE, OSSIA RILASCIATI&nbsp;NABOOK.CLOUD</strong
            >
          </p>
        </td>
      </tr>
      <tr>
        <td width="207">
          <p><strong>TIPOLOGIA DI COOKIE</strong></p>
        </td>
        <td width="529">
          <p><strong>CHE COSA FANNO?</strong></p>
        </td>
      </tr>
      <tr>
        <td width="207">
          <p>Tecnici/di sessione</p>
          <p>Tecnici/di navigazione</p>
          <p>
            <em
              >(Per rilasciare questo tipo di cookie non &egrave; necessario il
              consenso dell&rsquo;utente)</em
            >
          </p>
        </td>
        <td width="529">
          <p>
            Sono indispensabili per il corretto funzionamento del nostro sito e
            consentono all&rsquo;utente di navigare e di visualizzare i
            contenuti. Una loro eventuale disattivazione comporterebbe
            malfunzionamenti del sito.
          </p>
          <p>
            In genere, cookie di questo tipo sono necessari, ad esempio, per
            mantenere aperta una sessione di navigazione o per consentire
            all&rsquo;utente di accedere ad eventuali aree riservate. O ancora,
            possono ricordare temporaneamente i testi inseriti durante la
            compilazione di un modulo, quando si torna ad una pagina precedente
            nel corso della medesima sessione.
          </p>
        </td>
      </tr>
      <tr>
        <td width="207">
          <p>Tecnici/di funzionalit&agrave;</p>
          <p>
            <em
              >(Per rilasciare questo tipo di cookie non &egrave; necessario il
              consenso dell&rsquo;utente)</em
            >
          </p>
        </td>
        <td width="529">
          <p>
            Consentono all&rsquo;utente di sfruttare al meglio le
            peculiarit&agrave; del sito e di fruire di una navigazione
            pi&ugrave; confortevole. Il sito funziona in modo ideale se questi
            cookie sono abilitati; &egrave; possibile comunque decidere di non
            consentirne l&rsquo;attivazione sul proprio dispositivo.
          </p>
          <p>
            In generale, ad esempio, cookie di questo tipo ricordano in quale
            lingua l&rsquo;utente preferisce visualizzare i nostri contenuti o
            ricordano (per un periodo limitato) gli articoli presenti nel
            carrello virtuale nel caso in cui si chiuda la sessione prima di
            aver ultimato l&rsquo;acquisto.
          </p>
        </td>
      </tr>
      <tr>
        <td width="207">
          <p>Tecnici/consenso</p>
          <p>
            <em
              >(Per rilasciare questo tipo di cookie non &egrave; necessario il
              consenso dell&rsquo;utente)</em
            >
          </p>
        </td>
        <td width="529">
          <p>
            Questo cookie tiene traccia del consenso prestato dall&rsquo;utente
            all&rsquo;utilizzo dei cookie su questo sito, in modo da non
            riproporre &ndash; nelle successive visite &ndash;
            l&rsquo;informativa breve sui cookie e la richiesta di prestare il
            consenso.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="2" width="736">
          <p>
            <strong
              >COOKIE DI TERZE PARTI, OSSIA RILASCIATI DA SITI DIVERSI RISPETTO
              A&nbsp;NABOOK.CLOUD</strong
            >
          </p>
        </td>
      </tr>
      <tr>
        <td width="207">
          <p><strong>TIPOLOGIA DI COOKIE</strong></p>
        </td>
        <td width="529">
          <p><strong>CHE COSA FANNO?</strong></p>
        </td>
      </tr>
      <tr>
        <td width="207">
          <p>Statistici/di analytics</p>
          <p>
            <em
              >(Per rilasciare questo tipo di cookie &egrave; necessario il
              consenso dell&rsquo;utente)</em
            >
          </p>
        </td>
        <td width="529">
          <p>
            Sono utilizzati per raccogliere informazioni sulle modalit&agrave;
            di navigazione degli utenti sul nostro sito. Queste informazioni
            vengono analizzate in forma aggregata per soli fini statistici.
          </p>
          <p>
            Non sono necessari, ma sono per noi di grande aiuto per consentirci
            di migliorare i nostri contenuti ed i nostri servizi in base alle
            indicazioni che ricaviamo dall&rsquo;analisi delle statistiche.
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <h3><strong>Cookie di terze parti</strong></h3>
  <p>
    <strong
      >Navigando su questo sito riceverete sia cookie di Lainox Ali S.r.l., sia
      cookie da siti di terze parti, i quali potranno impostare cookie sul
      vostro dispositivo per nostro conto al fine di erogare i servizi che
      stanno fornendo.</strong
    >
  </p>
  <p>
    <strong
      >I cookie di terze parti ci consentono di ottenere sondaggi pi&ugrave;
      completi delle abitudini di esplorazione degli utenti. Utilizziamo tali
      cookie, ad esempio, per elaborare statistiche sull&rsquo;utilizzo del
      nostro sito e per valutare il vostro interesse nei riguardi di nostri
      specifici contenuti o servizi. Informazioni pi&ugrave; dettagliate su
      questi cookie sono disponibili sulle tabelle presenti in questo documento
      e sui rispettivi siti internet delle terze parti in questione.</strong
    >
  </p>
  <h3><strong>Cookie di statistica (di terze parti)</strong></h3>
  <p>
    <strong
      >Nabook.cloud utilizza Google Analytics, un servizio di analisi del
      traffico web fornito da Google, Inc., che fa uso di cookie per raccogliere
      ed analizzare in forma aggregata informazioni relative alla navigazione
      degli utenti. N&eacute; Lainox Ali S.r.l.&nbsp;n&eacute; Google associano
      il vostro indirizzo IP ad altri dati in loro possesso per identificarvi
      direttamente.</strong
    >
  </p>
  <p>
    <strong
      >Le informazioni raccolte vengono elaborate dai sistemi di Google
      Analytics allo scopo di produrre report per gli amministratori di
      nabook.cloud, che li utilizzano per verificare il corretto funzionamento
      dei servizi ed eventualmente il tasso di gradimento dei contenuti
      proposti.</strong
    >
  </p>
  <p>
    <strong
      >Qualora voleste disabilitare i cookie statistici impedendo cos&igrave; a
      Google Analytics di raccogliere dati sulla vostra navigazione, potete
      scaricare l&rsquo;apposito componente per la disattivazione di Google
      Analytics che trovate qui:&nbsp;</strong
    ><a href="https://tools.google.com/dlpage/gaoptout"
      ><strong>https://tools.google.com/dlpage/gaoptout</strong></a
    ><strong>.</strong>
  </p>
  <h3><strong>Come configurare il proprio terminale</strong></h3>
  <p>
    <strong
      >Qualora non fosse d&rsquo;accordo con l&rsquo;installazione dei cookie,
      l&rsquo;utente dovr&agrave; configurare il browser in modo da disabilitare
      la ricezione dei cookie o non usare questo sito. Disabilitando i cookie
      per&ograve;, il sito o alcune sue funzioni potrebbero non operare
      correttamente.</strong
    >
  </p>
  <p>
    <strong
      >Per modificare le modalit&agrave; di utilizzo dei cookie, per bloccarne
      la ricezione o ancora per eliminare i cookie presenti sul proprio
      terminale, &egrave; sufficiente entrare nelle impostazioni del proprio
      browser.</strong
    >
  </p>
  <p>
    <strong
      >La maggior parte dei browser prevede la possibilit&agrave; di accettare o
      rifiutare tutti i cookie o accettarne solo alcuni (ad esempio provenienti
      da specifici siti).</strong
    >
  </p>
  <p>
    <strong
      >Anche se attraverso passaggi simili, il metodo di configurazione del
      cookie varia per ciascun browser. Per avere dettagli in merito alla
      procedura da seguire l&rsquo;utente potr&agrave; visitare il
      sito&nbsp;</strong
    ><a href="http://www.aboutcookies.org/"
      ><strong>www.aboutcookies.org</strong></a
    ><strong
      >&nbsp;o consultare la sezione &lsquo;Aiuto&rsquo; del proprio
      browser.</strong
    >
  </p>
  <p>
    <strong
      >Per maggiori informazioni sui cookie e per gestire le tue preferenze sui
      cookie di terza parte ti invitiamo a visitare&nbsp;</strong
    ><a href="http://www.youronlinechoices.com/"
      ><strong>www.youronlinechoices.com</strong></a
    >.
  </p>
</div>
