<h2 class="mt-3">{{ 'USER_DETAIL.PERSONAL_INFO.TITLE' | translate }}</h2>
<div [formGroup]="controlValue">
  <div class="row">
    <div class="mb-3 col-12 col-md-6">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.NAME' | translate }}
        <div class="combo-input">
          <input
            type="text"
            class="custom-input"
            formControlName="firstName"
            [ngClass]="{
              'not-valid': !controlValue.controls['firstName'].valid
            }"
          />
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-6">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.SURNAME' | translate }}
        <div class="combo-input">
          <input
            type="text"
            class="custom-input"
            formControlName="lastName"
            [ngClass]="{
              'not-valid': !controlValue.controls['lastName'].valid
            }"
          />
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-6">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.EMAIL' | translate }}
        <div class="combo-input">
          <input
            readonly
            type="email"
            class="custom-input disabled-input"
            formControlName="email"
            [ngClass]="{ 'not-valid': !controlValue.controls['email'].valid }"
          />
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-6">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.NUMBER_PHONE' | translate }}
        <div class="combo-input">
          <input
            oninput="validity.valid||(value='');"
            type="number"
            min="0"
            class="custom-input"
            formControlName="phoneNumber"
            [ngClass]="{
              'not-valid': !controlValue.controls['phoneNumber'].valid
            }"
          />
        </div>
      </label>
    </div>
  </div>
  <div class="row end-section">
    <div class="mb-3 col-12 col-md-6">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.COUNTRY' | translate }}
        <div class="combo-input">
          <select
            class="custom-input"
            formControlName="country"
            [ngClass]="{ 'not-valid': !controlValue.controls['country'].valid }"
          >
            <option
              *ngFor="let country of countryItems"
              [ngValue]="country.id"
            >
              {{ country.value }}
            </option>
          </select>
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-2">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.DISTRICT' | translate }}
        <div class="combo-input">
          <select
            class="custom-input"
            formControlName="province"
          >
            <option
              *ngFor="let district of districtItems"
              [ngValue]="district.id"
            >
              {{ district.value }}
            </option>
          </select>
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-4">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.CITY' | translate }}
        <div class="combo-input">
          <input
            type="text"
            class="custom-input"
            formControlName="city"
            [ngClass]="{ 'not-valid': !controlValue.controls['city'].valid }"
          />
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-7">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.ADDRESS' | translate }}
        <div class="combo-input">
          <input
            type="text"
            class="custom-input"
            formControlName="address"
            [ngClass]="{ 'not-valid': !controlValue.controls['address'].valid }"
          />
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-2">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.ADDRESS_NUMBER' | translate }}
        <div class="combo-input">
          <input
            oninput="validity.valid||(value='');"
            type="number"
            min="0"
            class="custom-input"
            formControlName="addressNumber"
            [ngClass]="{
              'not-valid': !controlValue.controls['addressNumber'].valid
            }"
          />
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-3">
      <label class="custom-label">
        {{ 'USER_DETAIL.PERSONAL_INFO.CAP' | translate }}
        <div class="combo-input">
          <input
            oninput="validity.valid||(value='');"
            type="number"
            min="0"
            class="custom-input"
            formControlName="postalCode"
            [ngClass]="{
              'not-valid': !controlValue.controls['postalCode'].valid
            }"
          />
        </div>
      </label>
    </div>
  </div>
</div>
