<h2 class="mt-3">{{ 'USER_DETAIL.OVEN.TITLE' | translate }}</h2>
<div [formGroup]="controlValue">
  <div class="row">
    <div class="mb-3 col-12 col-md-4">
      <label class="custom-label">
        {{ 'USER_DETAIL.OVEN.BRAND' | translate }}
        <div class="combo-input">
          <select
            class="custom-input"
            formControlName="ovenBrand"
            [ngClass]="{
              'not-valid': !controlValue.controls['ovenBrand'].valid
            }"
          >
            <option *ngFor="let brand of brandItems" [ngValue]="brand.key">
              {{ brand.value }}
            </option>
          </select>
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-4">
      <label class="custom-label">
        {{ 'USER_DETAIL.OVEN.TYPE' | translate }}
        <div class="combo-input">
          <select
            class="custom-input"
            formControlName="ovenType"
            [ngClass]="{
              'not-valid': !controlValue.controls['ovenType'].valid
            }"
          >
            <option *ngFor="let type of typeItems" [ngValue]="type.key">
              {{ type.value }}
            </option>
          </select>
        </div>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="mb-3 col-12 col-md-3">
      <label class="custom-label">
        {{ 'USER_DETAIL.OVEN.CAPACITY' | translate }}
        <div class="combo-input">
          <input
            oninput="validity.valid||(value='');"
            type="number"
            min="0"
            class="custom-input"
            formControlName="ovenCapacity"
            [ngClass]="{
              'not-valid': !controlValue.controls['ovenCapacity'].valid
            }"
          />
        </div>
      </label>
    </div>
    <div class="mb-3 col-12 col-md-3">
      <label class="custom-label">
        {{ 'USER_DETAIL.OVEN.YEAR' | translate }}
        <div class="combo-input">
          <select
            class="custom-input"
            [ngModel]="ovenYearValue"
            formControlName="ovenYear"
            [ngClass]="{
              'not-valid': !controlValue.controls['ovenYear'].valid
            }"
          >
            <option *ngFor="let y of years" [ngValue]="y" >
              {{ y }}
            </option>
          </select>
          <!-- <input
            ngbDatepicker
            #dp="ngbDatepicker"
            (focus)="dp.toggle()"
            type="text"
            class="custom-input"
            formControlName="ovenYear"
            [ngClass]="{
              'not-valid': !controlValue.controls['ovenYear'].valid
            }"
          /> -->
          
          <!-- oninput="validity.valid||(value='');" -->
        </div>
      </label>
    </div>
  </div>
</div>
