<h2>{{ 'USER_DETAIL.COMPANY.TITLE' | translate }}</h2>
<div class="row" [formGroup]="controlValue">
  <div class="mb-3 col-12">
    <label class="custom-label">
      <input type="checkbox" formControlName="notWorking" />
      {{ 'USER_DETAIL.COMPANY.NOT_WORKING' | translate }}
    </label>
  </div>
  <div class="mb-3 col-12 col-md-4">
    <label class="custom-label">
      {{ 'USER_DETAIL.COMPANY.COMPANY' | translate }}
      <div class="combo-input">
        <input type="text" class="custom-input" formControlName="name" />
      </div>
    </label>
  </div>
  <div class="mb-3 col-12 col-md-4">
    <label class="custom-label">
      {{ 'USER_DETAIL.COMPANY.PIVA' | translate }}
      <div class="combo-input">
        <input type="text" class="custom-input" formControlName="vatNumber" />
      </div>
    </label>
  </div>
  <div class="mb-3 col-12 col-md-4">
    <label class="custom-label">
      {{ 'USER_DETAIL.COMPANY.NUMBER_PHONE' | translate }}
      <div class="combo-input">
        <input oninput="validity.valid||(value='');" min="0" type="number" class="custom-input" formControlName="phoneNumber" />
      </div>
    </label>
  </div>
  <div class="mb-3 col-12 col-md-4">
    <label class="custom-label">
      {{ 'USER_DETAIL.COMPANY.EMAIL' | translate }}
      <div class="combo-input">
        <input type="email" class="custom-input" formControlName="email" />
      </div>
    </label>
  </div>
  <div class="mb-3 col-12 col-md-4">
    <label class="custom-label">
      {{ 'USER_DETAIL.COMPANY.POSITION' | translate }}
      <div class="combo-input">
        <input type="text" class="custom-input" formControlName="position" />
      </div>
    </label>
  </div>
</div>
