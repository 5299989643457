import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  width$: Subject<number> = new Subject();
  toggleNavbar$: Subject<boolean> = new Subject();
  toggleHambuger$: Subject<boolean> = new Subject();
  lockLayout$: Subject<boolean> = new Subject();

  constructor() {
    this.width$
      .pipe(
        tap((res) => {
          if (res >= 1200) {
            this.lockLayout$.next(false);
          } else {
            this.toggleNavbar$.next(false);
          }
        })
      )
      .subscribe();
  }

  setCurrentWidth(width: number) {
    this.width$.next(width);
  }

}
