<h2 class="mt-3">{{ 'USER_DETAIL.SECTOR.TITLE' | translate }}</h2>
<div class="row" [formGroup]="controlValue">
  <div class="mb-3 col-12 col-md-4">
    <label class="custom-label">
      {{ 'USER_DETAIL.SECTOR.SECTOR' | translate }}
      <div class="combo-input">
        <select class="custom-input" formControlName="sector">
          <option *ngFor="let sector of sectorItems" [ngValue]="sector.key">
            {{ sector.value }}
          </option>
        </select>
      </div>
    </label>
  </div>
  <div class="mb-3 col-12 col-md-4">
    <label class="custom-label">
      {{ 'USER_DETAIL.SECTOR.MEALS' | translate }}
      <div class="combo-input">
        <input type="number" min="0" class="custom-input" formControlName="mealsPerDay" />
      </div>
    </label>
  </div>
</div>
