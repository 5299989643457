import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardBoDtoNBK, UserKeycloakDtoNBK } from '../api/nbk';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  counters: DashboardBoDtoNBK;
  kcUser: UserKeycloakDtoNBK;

  constructor(private route: ActivatedRoute) {
    this.counters = this.route.snapshot.data.dashboardData.counters;
    this.kcUser = this.route.snapshot.data.dashboardData.loggedUser;
  }

  ngOnInit(): void {}
}
