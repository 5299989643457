<ng-container [ngSwitch]="language">
  <div *ngSwitchCase="'it'">
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      nabookplus.cloud – WEB POLICY E INFORMATIVA PRIVACY
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Noi di Lainox - Ali Group S.r.l. a Socio Unico siamo felici della Sua
      visita e dell’interessamento ai nostri prodotti.<br />
      Diamo molto valore al fatto che Lei si possa fidare di noi e quindi
      desideriamo condividere con Lei le modalità di funzionamento e gestione
      del sito anche in rapporto al trattamento dei Suoi dati personali che
      raccogliamo ai fini della Sua iscrizione al portale Nabook.<br />
      Prima di utilizzare Nabook è necessario registrarsi ed aprire un account;
      in particolare, Le chiediamo, durante la registrazione, la conferma di
      aver letto, compreso e di fornire il consenso espresso alla Privacy
      Policy, senza i quali non è consentito l’utilizzo del portale Nabook.
      <br />
      I dati raccolti non verranno mai venduti o forniti a terzi. Il trattamento
      dei Suoi dati si basa sempre su principi di liceità e correttezza in
      ottemperanza a tutte le vigenti normative applicabili, in particolare
      l'informativa è resa anche ai sensi degli artt. 13 e 14 del GDPR -
      Regolamento UE 2016/679 a coloro che interagiscono con i servizi web
      accessibili per via telematica tramite le pagine di questo sito internet.
      Il portale Nabook utilizza cookies.<br />
      La presente privacy policy si applica esclusivamente alle attività online
      del presente sito ed è valida per i visitatori/utenti del sito. Non si
      applica alle informazioni eventualmente raccolte tramite canali diversi
      dal presente sito web.<br />
      Lo scopo dell'informativa privacy è di fornire la massima trasparenza
      relativamente alle informazioni che il sito raccoglie e come le usa.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      INFORMATIVA PRIVACY SUL TRATTAMENTO DEI DATI
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      I trattamenti connessi ai servizi web di questo sito hanno luogo presso la
      sede di Lainox di Ali Group S.r.l. a Socio Unico e sono curati solo dal
      personale appositamente incaricato per il trattamento e da eventuali
      incaricati di operazioni di manutenzione.<br />
      Al fine di prevenire la perdita dei dati, nonché usi illeciti o non
      corretti, sono attuate specifiche misure di sicurezza, atte a garantire la
      riservatezza e ad evitare l'indebito accesso di soggetti terzi o personale
      non autorizzato, in ottemperanza agli obblighi di legge.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TITOLARITÀ E RESPONSABILE DEL TRATTAMENTO DATI
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Titolare del trattamento dei dati è Lainox - Ali Group S.r.l. a Socio
      Unico, Via Schiaparelli, 15, 31029 – Vittorio Veneto (TV), Italia. <br />
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TIPI DI DATI TRATTATI<br />Dati raccolti e finalità
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      I server e i sistemi informatici preposti al funzionamento di questo sito
      web acquisiscono, nel corso del loro normale esercizio, alcuni dati la cui
      trasmissione è implicita nell'uso dei protocolli di comunicazione di
      Internet. Si tratta di informazioni che non sono raccolte per essere
      associate ad interessati identificati, ma che per loro stessa natura
      potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da
      terzi, permettere di identificare gli Utenti.<br />
      In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio
      dei computer utilizzati dagli Utenti che si connettono al sito, gli
      indirizzi in notazione URI (Uniform Resource Identifier) delle risorse
      richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre
      la richiesta al server, la dimensione del file ottenuto in risposta, il
      codice numerico indicante lo stato della risposta data dal server (buon
      fine, errore, etc.) ed altri parametri relativi al sistema operativo e
      all'ambiente informatico dell'Utente.<br />
      Questi dati vengono utilizzati al solo fine di controllare il corretto
      funzionamento del sito. I dati potrebbero inoltre essere utilizzati dalle
      forze di Pubblica Sicurezza per l'accertamento di responsabilità in caso
      di eventuali reati informatici ai danni del sito.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Dati forniti volontariamente dall'Utente
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. a Socio Unico processa e utilizza le
      informazioni personali solo se messe a disposizione in modo facoltativo,
      esplicito e volontario e solo se legalmente permesso. La raccolta delle
      informazioni è obbligatoria per poter accedere al servizio Nabook,
      pertanto per l’utilizzazione del sito Nabook è necessario registrarsi ed
      aprire un account. Come parte del processo di registrazione vengono
      raccolti l’indirizzo mail, il nome e cognome e il numero di telefono.<br />
      Tutte le informazioni (quali ad esempio il nome della società, la marca
      del forno) che potrebbero non essere sufficienti per determinare la Sua
      identità possono essere raccolte, ma non sono considerate informazioni
      personali.
      <br />
      I dati raccolti dal sito durante il suo funzionamento sono utilizzati
      esclusivamente per le finalità sopra indicate e conservati per il tempo
      strettamente necessario a svolgere le attività precisate. In ogni caso i
      dati personali rilevati dal sito non saranno forniti mai a terzi, se non
      per le finalità funzionali al loro trattamento, a meno che non si tratti
      di legittima richiesta da parte dell'autorità giudiziaria e nei soli casi
      previsti dalla legge. I dati però potranno essere forniti a terzi qualora
      ciò sia necessario per la fornitura di uno specifico servizio richiesto
      dell'Utente, oppure per l'esecuzione di controlli di sicurezza o di
      ottimizzazione del sito. I dati utilizzati a fini di sicurezza del sito
      (blocco tentativi di danneggiare il sito) sono conservati per 7 giorni
      lavorativi. <br />
      Per la gestione del processo di registrazione, il sito utilizza la
      procedura di “doppia conferma” (double opt-in) atto sia a verificare che
      la persona che effettua la richiesta di iscrizione da web sia il reale
      titolare dell'indirizzo email e sia a fornire un ulteriore espresso ed
      esplicito consenso all'accettazione delle condizioni di utilizzo del
      servizio, che significa che, per completare la Sua registrazione, Lei
      dovrà confermarla cliccando sul link che Le verrà inviato all’indirizzo di
      posta elettronica fornitoci. <br />
      Se non invierà il messaggio di conferma cliccando sul link entro un certo
      tempo, la Sua richiesta di registrazione verrà eliminata automaticamente
      dal database. I dati da Lei forniti potranno essere raccolti e utilizzati
      per finalità di marketing solo nel caso in cui Lei ci abbia fornito
      esplicita autorizzazione a tale utilizzo.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Trasferimento dei dati all’estero.
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      I Suoi dati personali sono trattati nell’ambito dell’Unione Europea o al
      più in Paesi che forniscano lo stesso livello di tutela dei dati personali
      garantito dal regolamento UE sulla protezione dei dati personali.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Luogo del trattamento
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      I dati raccolti dal sito sono trattati presso la sede del Titolare del
      Trattamento, e presso il datacenter del web Hosting. Il web hosting si
      trova nello Spazio Economico Europeo e agisce in conformità delle norme
      europee.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COOKIES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Per informazioni relative all’uso dei cookies su questo sito potete
      consultare la nostra informativa estesa – Cookie Policy.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Esistenza di un processo decisionale automatizzato, compresa la
      profilazione
    </p>
    <p
      style="
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 14pt;
        font-family: Calibri, san-serif;
      "
    >
      Lainox - Ali Group S.r.l. a Socio Unico non adotta alcun processo
      decisionale automatizzato, compresa la profilazione, di cui all’articolo
      22, paragrafi 1 e 4, del Regolamento UE n. 679/2016.
    </p>
    <p
      style="
        margin-bottom: 0px;
        margin-top: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      DIRITTI DEGLI INTERESSATI
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      In ogni momento, Lei potrà esercitare, ai sensi degli articoli dal 15 al
      22 del Regolamento UE n. 2016/679, il diritto di:<br />
      a) chiedere la conferma dell’esistenza o meno di propri dati personali;<br />
      b) ottenere le indicazioni circa le finalità del trattamento, le categorie
      dei dati personali, i destinatari o le categorie di destinatari a cui i
      dati personali sono stati o saranno comunicati e, quando possibile, il
      periodo di conservazione;<br />
      c) ottenere la rettifica e la cancellazione dei dati;<br />
      d) ottenere la limitazione del trattamento;<br />
      e) ottenere la portabilità dei dati, ossia riceverli da un titolare del
      trattamento, in un formato strutturato, di uso comune e leggibile da
      dispositivo automatico, e trasmetterli ad un altro titolare del
      trattamento senza impedimenti;<br />
      f) opporsi al trattamento in qualsiasi momento ed anche nel caso di
      trattamento per finalità di marketing diretto;<br />
      g) opporsi ad un processo decisionale automatizzato relativo alle persone
      ﬁsiche, compresa la profilazione.<br />
      h) chiedere al titolare del trattamento l’accesso ai dati personali e la
      rettifica o la cancellazione degli stessi o la limitazione del trattamento
      che lo riguardano o di opporsi al loro trattamento, oltre al diritto alla
      portabilità dei dati;<br />
      i) revocare il consenso in qualsiasi momento senza pregiudicare la liceità
      del trattamento basata sul consenso prestato prima della revoca;<br />
      j) proporre reclamo a un’autorità di controllo.<br /><br />

      L’esercizio di tali diritti può, in ogni caso, essere ritardato, limitato
      o escluso con comunicazione motivata e resa senza ritardo all’interessato,
      per il tempo e nei limiti in cui costituisca una misura necessaria e
      proporzionata, tenuto conto dei diritti fondamentali e dei legittimi
      interessi dell’interessato, al fine di salvaguardare gli interessi di cui
      al comma 1, lettere a), b), d) ed e). In tali casi, i diritti
      dell'interessato possono essere esercitati anche tramite il Garante; le
      Sue richieste vanno rivolte a Lainox - Ali Group S.r.l. a Socio Unico, Via
      Schiaparelli, 15, 31029 – Vittorio Veneto (TV), Italy, al numero di
      telefono +39 0438 9110 oppure a mezzo e-mail all’indirizzo
      privacy.cr&#64;aligroup.it
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LIMITI DI RESPONSABILITÀ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. a Socio Unico è la sola responsabile della
      piattaforma Nabook in termini di relazione con Lei. Il funzionamento
      tecnico del portale Nabook può essere gestito per conto di Lainox - Ali
      Group S.r.l. a Socio Unico da parte di un fornitore terzo, che memorizza
      tutti i dati acquisiti come parte del processo di gestione di Nabook, dati
      che sono memorizzati in server fisicamente ubicati nel Regno Unito. Nabook
      è accessibile solamente tramite Internet: Lainox - Ali Group S.r.l. a
      Socio Unico non è in alcun modo responsabile della connessione Internet né
      dell’eventuale connessione degli apparecchi. Lainox - Ali Group S.r.l. a
      Socio Unico si prefigge di assicurare la continuità del servizio e di
      ridurre al minimo le eventuali disfunzioni imputabili a problemi tecnici;
      non può, tuttavia, garantire che il servizio sia o rimanga disponibile né
      che non sia soggetto a interruzioni o malfunzionamenti, né che i dati ed
      il contenuto del sito sia mantenuto nel tempo, pertanto Lainox - Ali Group
      S.r.l. a Socio Unico non si assume alcuna responsabilità per i danni di
      qualsiasi natura che possano derivare dall’utilizzo del sito o
      dall’impossibilità di accedervi. Né risponde di eventuali malfunzionamenti
      nelle connessioni ai siti esterni che potranno essere collegati a questo
      sito.
      <strong
        >Lainox - Ali Group S.r.l. a Socio Unico non risponde degli eventuali
        danni causati dall’utilizzazione dei contenuti (dati, informazioni,
        software, ecc.) presenti su questo sito. In particolare, Lainox - Ali
        Group S.r.l. a Socio Unico non si assume alcuna responsabilità
        nell’utilizzo di ingredienti e/o prodotti presenti in ricette o altri
        dati presenti all’interno di Nabook da persone che ne siano allergiche o
        abbiano reazioni in alcun modo danneggianti provenienti da essi.</strong
      >
      Le informazioni sui singoli ingredienti sono, infatti, da intendersi
      indicative, e Lei ne ha piena responsabilità potendo modificare ogni
      singola proprietà delle schede ingrediente disponibili. In particolare:<br />
      - Le calorie, solo indicative, sono sempre espresse in kcal e si
      riferiscono a 100 grammi di ingrediente, a porzione ove indicato, salvo
      altra precisazione.<br />
      - I prezzi, solo indicativi, si riferiscono ad una unità dell’unità di
      misura principale definita per il singolo ingrediente, salvo altra
      precisazione.<br />
      - Gli allergeni, solo indicativi, riportati nella scheda ingrediente sono
      quelli comunemente e potenzialmente presenti nel tipo di ingrediente.<br />
      Lainox - Ali Group S.r.l. a Socio Unico si riserva di modificare i
      contenuti del sito in qualsiasi momento e senza alcun preavviso.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COLLEGAMENTI A NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Qualsiasi forma di collegamento al presente sito deve essere
      preventivamente autorizzata da Lainox - Ali Group S.r.l. a Socio Unico. È
      vietato il c.d. ‘deep linking’, ossia l'utilizzo non trasparente, su siti
      di terzi, anche solo di parti del presente sito.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LICENZA DI UTILIZZO DEL PORTALE NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. a Socio Unico è lieta di concederLe una licenza
      non esclusiva e non trasferibile per l’utilizzo attualmente libero del
      portale Nabook. Ci si riserva di addebitare una tariffa per l’utilizzo di
      Nabook in futuro e di annullare l’attuale utilizzo gratuito. In ogni caso
      non è consentita la compensazione di un’eventuale futura tariffa con altri
      reclami, né di utilizzare alcun diritto di ritenuta, a meno che la
      richiesta o il diritto non siano stati riconosciuti per iscritto.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TERMINI CONTRATTUALI
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Il presente accordo per i termini e le condizioni d’uso di Nabook ha una
      validità a tempo indefinito. Tuttavia, esso può essere liberamente risolto
      da una delle due parti con un periodo di preavviso di 4 settimane a
      partire dalla data di comunicazione della volontà di risoluzione. Lainox -
      Ali Group S.r.l. a Socio Unico sarà quindi autorizzata a bloccarLe
      l’accesso a Nabook e non avrà alcun obbligo a mantenere le informazioni
      e/o i dati specificatamente presenti nel Suo account.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      PROPRIETÀ INTELLETTUALE, FILE E DATI E AUTORIZZAZIONI
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Quando Lei utilizza Nabook caricando ricette personali e personalizzandolo
      in varie forme, Lei ci fornisce file (come ad esempio cicli di cottura
      personali, o ingredienti personali), contenuti (ad esempio descrizioni,
      procedimenti o fotografie) e altro ancora (di seguito definito: "Il Suo
      contenuto"). Il Suo contenuto è e rimarrà di Sua proprietà. I presenti
      Termini non concedono a Lainox - Ali Group S.r.l. a Socio Unico nessun
      diritto sul Suo contenuto ad eccezione dei diritti limitati che consentono
      a Lainox - Ali Group S.r.l. a Socio Unico di offrire i Servizi. È e
      resterà sempre cura di Lainox - Ali Group S.r.l. a Socio Unico proteggere
      il Suo contenuto da accessi non autorizzati.<br />
      Lainox - Ali Group S.r.l. a Socio Unico ha bisogno del Suo permesso per
      eseguire operazioni quali gestire l'hosting dei file, farne un backup,
      sincronizzare il Suo contenuto con gli apparecchi e condividerli quando è
      richiesto di farlo. I Servizi di Nabook possono offrire inoltre funzioni
      quali miniature di foto, anteprime di documenti, aggiunta di commenti e
      modi semplici per ordinare, modificare, condividere e cercare il Suo
      contenuto. Queste e altre funzioni potrebbero richiedere l'accesso,
      l'archiviazione e la scansione di tutto o parte del Suo contenuto da parte
      del sistema. Lei concede a Lainox - Ali Group S.r.l. a Socio Unico il
      permesso di eseguire tali operazioni e tale permesso si estende ad
      affiliati e a terze parti affidabili con le quali Lainox - Ali Group
      S.r.l. a Socio Unico collabora.<br />
      In base al presente accordo per i termini e le condizioni d’uso di Nabook,
      i diritti sulle Sue informazioni di utilizzo non personali e/o sui dati,
      in qualsiasi forma, inviati a Lainox - Ali Group S.r.l. a Socio Unico per
      la valutazione o pubblicazione sono concessi a Lainox - Ali Group S.r.l. a
      Socio Unico in forma gratuita, universale, irrevocabile non esclusiva a
      tempo indefinito per poterli utilizzare su qualsiasi supporto, elaborarli,
      riprodurli, pubblicarli, tradurli e diffonderli. Lei ci concede inoltre i
      diritti a trasmettere a nostra volta tali diritti a terzi. Lainox - Ali
      Group S.r.l. a Socio Unico si riserva il diritto di modificare o eliminare
      anche solo parzialmente quanto trasmesso o pubblicato in Nabook o che è
      ospitato o memorizzato nei nostri server. Lainox - Ali Group S.r.l. a
      Socio Unico non ha l’obbligo di monitorare o sorvegliare quanto trasmesso
      e pubblicato da Lei in Nabook, che in ogni caso non può essere illegale o
      illegittimo e non deve violare i diritti di altri.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COPYRIGHT
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. a Socio Unico rispetta la proprietà
      intellettuale altrui e Le chiediamo di fare lo stesso. Lainox - Ali Group
      S.r.l. a Socio Unico risponde ad avvisi relativi a presunte violazioni del
      copyright, se conformi alla legge e se ci vengono comunicati tramite le
      Norme sul copyright. Ci si riserva il diritto di eliminare o disattivare
      contenuti che rappresentano presunte violazioni e di eliminare gli account
      che violano il copyright.<br />
      Ogni diritto sui contenuti (solo a titolo esemplificativo testi, immagini,
      layout grafici, loghi, costruzione delle pagine, architettura del sito,
      contenuti testuali, etc.) è riservato ai sensi della normativa vigente. I
      contenuti delle pagine del sito non possono essere - né totalmente né in
      parte - copiati, riprodotti, trasferiti, caricati, pubblicati o
      distribuiti in qualsiasi modo senza preventivo consenso scritto, fatta
      salva la possibilità di immagazzinarli nel proprio computer o di stampare
      estratti delle pagine di questo sito, in particolare per quei servizi che
      il sito stesso fornisce quali, solo a titolo esemplificativo, i menu, i
      food cost e le liste della spesa.<br />
      Tutti i marchi ed i loghi appartenenti a Lainox - Ali Group S.r.l. a Socio
      Unico visualizzati sulle pagine del sito web sono proprietà esclusiva
      della stessa Lainox - Ali Group S.r.l. a Socio Unico; qualsiasi uso e/o
      riproduzione degli stessi per qualunque scopo o mezzo è espressamente e
      severamente vietato e verrà perseguito dalle leggi competenti in materia.
      Eventuali marchi e loghi appartenenti a terzi visualizzati sul sito sono
      proprietà esclusiva dei loro titolari che ne hanno autorizzato l'utilizzo
      a Lainox - Ali Group S.r.l. a Socio Unico: qualsiasi loro uso e/o
      riproduzione è altresì severamente vietata.<br />
      <strong
        >Le presenti Condizioni d’uso, la Politica sul Trattamento dei Dati e la
        Politica sui Cookies costituiscono l’accordo tra Lei e Lainox - Ali
        Group S.r.l. a Socio Unico per quanto riguarda l’utilizzo di Nabook.
        L'eventuale inosservanza delle presenti disposizioni, salvo esplicita
        autorizzazione scritta, sarà perseguita nelle competenti sedi
        giudiziarie civili e penali.</strong
      >
      <br /><br />
      Vittorio Veneto, 25/05/18
    </p>
  </div>

  <div *ngSwitchCase="'ru'">
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      nabookplus.cloud – ПОЛИТИКА ВЭБСАЙТА И КОНФИДЕНЦИАЛЬНОСТИ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Мы, компания Lainox - Ali Group S.r.l. с единоличным участником рады
      вашему посещению и интересу к наших продуктам. Мы ценим тот факт, что вы
      можете нам доверять, и поэтому мы хотим поделиться с вами тем, как
      функционироует сайт и как мы управляем им, а также в отношении обработки
      ваших персональных данных, которые мы собираем для вашей регистрации на
      портале Nabook. Сбор информации необходим для доступа к сервису Nabook,
      поэтому, для использования сайта Nabook нужно зарегистрироваться и создать
      свой аккаунт. В частности, мы просим во время регистрации, подтвердить что
      вы прочитали, поняли и предосталвяете ваше согласие с Политикой
      конфиденциальности, без которой использование портала Nabook запрещено.<br />
      Собранные данные никогда не будут проданы или предоставлены третьим
      сторонам. Обработка ваших данных всегда основана на принципах законности и
      справедливости в соответствии со всеми действующими правилами, в частности
      информация также предоставляется в соответствии со статьями. 13 и 14
      Общего регламента ЕС по защите персональных данных - ЕС 2016/679 тем, кто
      взаимодействует с веб-службами, доступными телематическими средствами
      через страницы этого веб-сайта. На портале Nabook используются файлы
      cookie.<br />
      Эта политика конфиденциальности применяется только к деятельности в онлайн
      режиме этого сайта и действительна для посетителей / пользователей сайта.
      Она не применяется к какой-либо информации, собранной по каналам, отличным
      от этого веб-сайта.<br />
      Цель информативного сообщения о конфиденциальности - обеспечить
      максимальную прозрачность информации, которую собирает сайт и как она
      используется.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ В СФЕРЕ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Обработка данных, связанная с веб-сервисами этого сайта, выполняется по
      юридическому адресу компании Lainox Ali Group S.r.l с единоличным
      участником, и их обслуживают только сотрудники, специально назначенные для
      этого, и возможные лица, ответственные за операции по техническому
      обслуживанию. В целях предотвращения потери данных, а также незаконного
      или неправильного использования применяются конкретные меры безопасности,
      чтобы гарантировать конфиденциальность и избежать необоснованный доступ
      третьих лиц или неавторизованного персонала, согласно предписаниям закона.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ВЛАДЕЛЕЦ И ОТВЕТСТВЕННЫЙ ЗА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Владельцем обработки персональных данных является Lainox - Ali Group
      S.r.l. С единоличным усастником Via Schiaparelli, 15, 31029.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ВИДЫ ОБРАБАТЫВЕМЫХ ДАННЫХ<br />Собранные данные и их назначение
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Серверы и компьютерные системы, используемые для работы этого веб-сайта,
      приобретают во время нормальной работы некоторые данные, передача которых
      безусловна в использовании протоколов интернет-связи. Это информация,
      которая не собирается, чтобы быть связанной с идентифицированными
      заинтересованными сторонами, но которая по самой своей природе может,
      посредством обработки и ассоциации с данными, принадлежащими третьим
      сторонам, разрешить идентифицировать пользователей. Эта категория данных
      включает в себя IP-адреса или доменные имена компьютеров, используемых
      пользователями, которые подключаются к сайту, адреса Uniform Resource
      Identifier (URI) запрашиваемых ресурсов, время запроса, метод,
      используемый для отправки запроса на сервер, размер файла, полученного в
      ответ, числовой код, указывающий состояние ответа сервера (успех, ошибка и
      т. д.) и другие параметры, относящиеся к операционной системе и ИТ-среде
      пользователя.<br />
      Эти данные используются только для проверки правильности функционирования
      сайта. Данные могут также использоваться полицей для установления
      ответственности в случае любых компютерных правонарушений против сайта.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Данные добровольно предоставляемые пользователем
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. с единоличным участником обрабатывает и
      использует персональную информацию только в том случае, если она
      предоставляется в факультативном, четком и добровольном порядке и только
      если это разрешено законом. Сбор информации является обязательным для
      доступа к услугам Nabook, поэтому для использования сайта Nabook вам
      необходимо зарегистрироваться и открыть учетную запись. В процессе
      регистрации предоставляются со стороны клиента адрес электронной почты,
      имя, фамилия и номер телефона. Вся информация (например, название
      компании, марка печи), которая может оказаться недостаточной для
      определения вашей личности, может быть собрана, но не считается
      персональной информацией. <br />
      Данные, собранные сайтом во время его функционирования, используются
      исключительно для указанных выше целей и хранятся в течение времени,
      строго необходимого для выполнения указанных действий. В любом случае
      персональные данные, собранные с сайта, никогда не будут предоставляться
      третьим сторонам, за исключением функциональных целей их обработки, в
      случае их законного запроса со стороны судебного органа и только в
      случаях, предусмотренных законом. Однако данные могут предоставляться
      третьим сторонам, если это необходимо для предоставления конкретной
      услуги, запрашиваемой пользователем, или для выполнения проверок
      безопасности или оптимизации сайта. Данные, используемые для обеспечения
      безопасности сайта (блокирование попыток взлома сайта), хранятся в течение
      7 рабочих дней.<br />
      Для управления процессом регистрации, сайт использует процедуру "двойного
      подтверждения" (double opt-in) для верификации человека, который
      регистрируется на сайте, для проверки адреса электронной почты и для
      получения дальнейшего согласия и принятия условий пользования сервисом.
      Это означает, что для того, чтобы закончить регистрацию Вам нужно перейти
      по ссылке, которую Вы получите по электронной почте. Если не будет
      получено подтверждение переходом на ссылку, Ваш запрос на регистрацию
      будет отклонен и удален из базы данных. Предоставленные Вами данные могут
      быть сохранены и использованы в маркетинговых целях только в случае, если
      Вы предоставили нам прямое разрешение на такое использование.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Передача данных за рубеж.
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Ваши персональные данные обрабатываются в Европейском Союзе или, в
      странах, которые обеспечивают такой же уровень защиты персональных данных,
      как это гарантировано правилами ЕС по защите персональных данных.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Место обработки
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Данные, собранные сайтом, обрабатываются по юридическому адресу Владельца
      обрабоки данных и в центре обработки данных веб-хостинга. Веб-хостинг
      расположен в Европейской экономической зоне и действует в соответствии с
      европейскими стандартами.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ФАЙЛЫ COOKIES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Для получения информации по использованию файлов cookie нашим сайтом,
      ознакомьтесь с нашим информационным письмом – Политика в области
      использования файлов cookie.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Существование автоматизированного процесса принятия решений, включая
      профилирование
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. с единоличным участником не использует никакого
      автоматизированного процесса принятия решений, включая профилирование,
      упомянутое в пунктах 1 и 4 статьи 22 Регламента ЕС. 679/2016.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ПРАВА ЗАИНТЕРЕСОВАННЫХ ЛИЦ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      В любой момент, Вы имеете право, согласно статьям 15 и 22 Регламента ЕС
      №2016/679:<br />
      а) запрашивать подтверждение присутствия или отсутствия собственных
      персональных данных;<br />
      b) получать информацию о целях обработки, категориях персональных данных,
      получателей или категорий получателей, которым были переданы или будут
      переданы личные данные и, по возможности, период их хранения;<br />
      c) добиваться исправления и удаления данных;<br />
      d) получать ограничение на обработку данных;<br />
      e) добиваться переноса данных, т.е. получать их от владельца данных, в
      структурированном формате, обычно используемом и читаемом автоматическим
      устройством, и беспрепятственно передавать их другому владельцу обработки
      данных;<br />
      f) выступать против обработки в любое время, а также в случае обработки
      для целей прямого маркетинга;<br />
      g) противодействовать автоматизированному процессу принятия решений в
      отношении физических лиц, включая профилирование;<br />
      h) запрашивать у Владельца обработки данных доступ к личным данным и
      исправлять или отменять или ограничивать их обработку или пвозражать их
      обработке в дополнение к праву на перенос данных;<br />
      i) отзывать в любой момент согласие на обрабоку перпсональных данных без
      ущерба для законности обратоки, основанной на согласии, предоставленного
      до отзыва;<br />
      j) подавать жалобу в надзорный орган.<br />
      Осуществление этих прав в любом случае может быть отложено, ограничено или
      исключено путем обоснованного сообщения и незамедлительно направленного
      заинтересованному лицу в рамках и сроках, в которых оно представляет собой
      необходимую и пропорциональную меру, с учетом основных прав и законных
      интересов заинтересованной стороны в целях защиты интересов, указанных в
      разделе 1, пункты а), b), d) и е). В таких случаях права заинтересованной
      стороны также могут осуществляться через Гаранта.<br />
      Ваши запросы будут направлены в компанию Lainox - Ali Group S.r.l. c
      единоличным участником, Via Schiaparelli, 15, 31029 –Vittorio Veneto (TV),
      Italy (Италия), по номеру телефона +39 0438 9110 или же на электронную
      почту по адресу privacy.cr&#64;aligroup.it.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ГРАНИЦЫ ОТВЕТСТВЕННОСТИ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Компания Lainox - Ali Group S.r.l. c единоличным участником отвечает за
      платформу Nabook во время Вашей работы с порталом. Техническое
      функционирование портала Nabook для компании Lainox - Ali Group S.r.l. c
      единоличным участником обеспечивает сторонняя компания, которая сохраняет
      все полученные данные, как часть процесса работы Nabook. Эти сохраненные
      данные физически хранятся на сервере в Великобритании. Nabook доступен
      только через Интернет: Lainox - Ali Group S.r.l. c единоличным участником
      не несет ответственность ни за интернет соединение ни за возможное
      подключенное оборудование. Lainox - Ali Group S.r.l. c единоличным
      участником ставит своей целью обеспечить бесперебойную работу и свести к
      минимуму возможные неполадки, вызванные техническими проблемами. Однако мы
      не может гарантировать, что сервис всегда будет доступен, будет работать
      без перебоев и что все данные будут постоянно доступны. Поэтому компания
      Lainox - Ali Group S.r.l. c единоличным участником не берет на себя
      ответственность за ущерб, причиненный использованием сайта или же
      вызванный невозможностью зайти на портал. Мы также не несем
      ответственности за плохое соединение со сторонними сайтами, которые могут
      быть соеденены с этим сайтом.<br />
      <strong
        >Компания Lainox - Ali Group S.r.l. c единоличным участником не несет
        ответственности за возможный ущерб, вызванный использованием содержимого
        сайта (данные, информация, программное обеспечение и т.д.). В частности,
        компания Lainox - Ali Group S.r.l. c единоличным участником не несет
        ответственности за использование ингредиентов и/или продуктов в рецептах
        или в другой информации на нашем сайте, если это использование привело к
        аллергической реакции или нанесло другой вред.</strong
      >
      Информация по отдельным ингредиентам имеет ориентировочный характер и Вы
      можете менять ингредиенты по своему усмотрению. В частности:<br />
      - Подсчет калорий имеет ориентировочное значение. Значения выражены в
      килокалориях и рассчитаны для 100 грамм продукта, для порции, где это
      указано, за исключением особых обозначений.<br />
      - Цены носят приблизительный характер и указаны за единицу измерения
      продукта для каждого отдельного ингредиента, за исключением других
      предписаний.<br />
      - Аллергены, ориентировочного характера и указаны в карточке продукта,
      являеются обычными аллегренами, присутствующими в определенном типе
      ингредиентов.<br />
      Компания Lainox - Ali Group S.r.l. c единоличным участником оставляет за
      собой право изменять содержимое сайта в любое время и без какого-либо
      предупреждения.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      СОЕДИНЕНИЯ С NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Любое соединение с сайтом должно осуществляться только после авторизации
      со стороны Lainox - Ali Group S.r.l. c единоличным участником. Запрещены
      внешние ссылки (deep linking), т. е. любое не прозрачное использование на
      сторонних сайтах, в том числе только частей настоящего сайта.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ЛИЦЕНЗИЯ НА ИСПОЛЬЗОВАНИЕ NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Компания Lainox - Ali Group S.r.l. c единоличным участником рада
      предоставить Вам не эксклюзивную и без права передачи третьим лицам
      лицензию на свободное использование портала Nabook. Мы оставляем за собой
      право ввести оплату за пользование Nabook в будущем и аннулировать
      настоящую бесплатную лицензию. В любом случае, не будет введена
      компенсация или вычеты за возможное платное пользование в будущем, за
      исключением случая, если таковы условия были указаны в письменном виде.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      СРОКИ ИСПОЛНЕНИЯ ДОГОВОРА
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Срок использования Nabook согласно данному соглашению является
      неопределенным. Однако он может быть свободно расторжен одной из двух
      сторон с 4-недельным уведомлением, начиная с даты об объявлении желания на
      расторжение. Lainox - Ali Group S.r.l. c единоличным участником будет
      уполномочена заблокировать ваш доступ к Nabook и не будет обязана
      содержать информацию и / или данные, конкретно представленные в вашей
      учетной записи.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ИНТЕЛЕКТУАЛЬНЫЕ ПРАВА, ФАЙЛЫ, ДАННЫЕ И РАЗРЕШЕНИЯ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Когда Вы используете Nabook для загрузки личных рецептов, персонализируя
      их в различных формах, Вы присылаете нам файл (например, свой вариант
      приготовления или ваши ингредиенты), содержимое (например описания,
      последовательность действий или фотографии) или же иную информацию (далее
      "Ваш контент"): Ваш контент остается вашей собственностью. Настоящие
      условия не дают никакого права компании Lainox - Ali Group S.r.l. c
      единоличным участником на Ваш контент, за исключением ограниченных прав,
      которые позволяют компании предоставлять Вам услуги. Компания Lainox - Ali
      Group S.r.l. c единоличным участником будет всегда защищать Вашу
      интеллектуальную собственность от постороннего доступа.<br />
      Компания Lainox - Ali Group S.r.l. c единоличным участником нуждается в
      Вашем согласии на выполнение таких операций как хостинг файлов, резервное
      копирование файлов, синхронизацию Вашего контента с приборами и
      распространении контента по Вашему запросу. Сервисы Nabook предлагают Вам
      множество функций, среди которых минимизация фото, добавление комментариев
      и простой и легкий способ для создания, изменения и распространения Вашего
      контента. Эти и другие функции могут запросить доступ, архивацию или
      сканирование всего или только части Вашего контента нашей системой. Вы
      предоставляете Lainox - Ali Group S.r.l. c единоличным участником Ваше
      согласие на выполнение подобных операций и оно распространяется на наших
      партнеров или на сторонние компании, с которыми сотрудничает Lainox - Ali
      Group S.r.l. c единоличным участником.<br />
      На основании данного соглашения об условиях использования Nabook, права на
      обработку Вашей информации, открытой для общего доступа, и/или Ваших
      данных, в любой форме, отправленных в компанию Lainox - Ali Group S.r.l. c
      единоличным участником. для оценки и публикации, передаются Lainox - Ali
      Group S.r.l. c единоличным участником. на безвозмездной основе,
      безвозвратно, целиком и полностью в не исключительное пользование, на
      неограниченное время, для их использования на любой платформе, обработки,
      воспроизведения, публикации, перевода и распространения. Вы предоставляете
      нам право на передачу этих прав третьим лицам. Компания Lainox - Ali Group
      S.r.l. c единоличным участником оставляет за собой право частично или
      полностью изменять или удалять данные, переданные в Nabook или хранящиеся
      на наших серверах. Компания Lainox - Ali Group S.r.l. c единоличным
      участником не обязана отслеживать или проверять данные, загруженные или
      опубликованные Вами в Nabook. Вы не имеете права публиковать незаконную,
      нелегальную или дискриминирующую информацию.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      АВТОРСКОЕ ПРАВО
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Компания Lainox - Ali Group S.r.l. c единоличным участником уважает чужую
      интеллектуальную собственность и ожидает от Вас подобного уважительного
      отношения. Компания Lainox - Ali Group S.r.l. c единоличным участником
      реагирует на предупреждения о нарушениях в отношении авторского права,
      если они соответствуют законодательству и будут переданы нам с
      использованием норм в области защиты авторского права. Мы оставляем за
      собой право удалять или блокировать контент с нарушениями или удалять
      аккаунт, если таковой нарушает авторское право.<br />
      Все права на контент (например: тексты, изображения, схемы, логотипы,
      эскизы страниц, архитектуру сайта, тексты и .д.) защищены в соответствии с
      действующим законодательством. Содержимое страниц сайта не может быть - ни
      целиком ни частично - скопировано, воспроизведено, передано, загружено,
      опубликовано или распространено без предварительного письменного
      разрешения, за исключением сохранения содержимого сайта на личном
      компьютере и распечатки его содержимого с помощью сервисов сайта,
      например, меню, стоимость блюд и список покупок.<br />
      Все марки и логотипы, принадлежащие компании Lainox - Ali Group S.r.l. c
      единоличным участником и представленные на страницах веб-сайта, являются
      ее исключительной собственностью. Их любое использование и/или
      воспроизведение в любых целях или любыми средствами категорически
      запрещено и будет преследоваться по закону, действующему в данной области.
      Возможные марки и логотипы, принадлежащие третьим сторонам и
      представленные на сайте, являются исключительной собственностью их
      владельцев, которые предоставили разрешение на их использование компании
      Lainox - Ali Group S.r.l. c единоличным участником. Их любое использование
      и/или воспроизведение также категорически запрещено.<br />
      <strong
        >Настоящие условия использования, политика обработки персональных данных
        и политика в области использования файлов cookie представляют собой
        соглашение между Вами и компанией Lainox - Ali Group S.r.l. c
        единоличным участником о правилах использования портала Nabook.
        Несоблюдение вышеперечисленных правил, за исключением письменного
        разрешения, будет преследоваться в судебных гражданских и уголовных
        органах.</strong
      >

      <br /><br />
      Vittorio Veneto, 25/05/18
    </p>
  </div>

  <div *ngSwitchCase="'fr'">
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      nabookplus.cloud – POLITIQUE WEB ET NOTE D'INFORMATION SUR LA
      CONFIDENTIALITÉ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. à Associé Unique est ravie de votre visite et de
      l’intérêt montré pour nos produits.<br />
      Votre confiance est extrêmement importante, nous souhaitons donc partager
      avec vous les modalités de fonctionnement et de gestion du site même en
      rapport au traitement de vos données fournies lors de l’inscription au
      portail Nabook. Cette notice d’information sur la confidentialité ne
      s’applique qu’aux activités en ligne de ce site et n’est valable que pour
      les visiteurs/utilisateurs du site. Elle n’est pas d’application aux
      informations éventuellement collectées par des canaux autres que ce site
      web.<br />
      Cette notice d’information sur la confidentialité vise à assurer le
      maximum de transparence pour ce qui est des informations collectées par le
      site et de leur utilisation.<br />
      Avant d’utiliser Nabook il faut s’enregistrer et ouvrir un compte ; nous
      vous demandons surtout, lors de l’enregistrement, de confirmer d’avoir lu
      et compris et d’accepter la politique de confidentialité sans quoi
      l’utilisation du portail Nabook ne peut être autorisée. Les informations
      collectées ne seront jamais vendues ou cédées à des tiers. Le traitement
      de vos données se base toujours sur des principes de licéité et de
      correction conformément à toutes les normes en vigueur applicables, en
      particulier la note d’information se rapporte aux termes des articles 13
      et 14 du RGPD - Règlement UE 2016/679 à ceux qui interagissent avec les
      services web accessibles par voie télématique à travers les pages de ce
      site internet. Le portail Nabook utilise des cookies. Cette notice
      d’information sur la confidentialité ne s’applique qu’aux activités en
      ligne de ce site et n’est valable que pour les visiteurs/utilisateurs du
      site. Elle n’est pas d’application aux informations éventuellement
      collectées par des canaux autres que ce site web.<br />
      Cette notice d’information sur la confidentialité vise à assurer le
      maximum de transparence pour ce qui est des informations collectées par le
      site et de leur utilisation.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      NOTE D'INFORMATION SUR LA CONFIDENTIALITÉ ET SUR LE TRAITEMENT DES DONNÉES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Les traitements liés aux services web de ce site se font auprès du siège
      de Lainox - Ali Group S.r.l. à Associé Unique uniquement par le personnel
      spécialement chargé du traitement et par d’éventuels préposés d’opérations
      d’entretien.<br />
      Pour éviter la perte des données, des mesures spécifiques de sécurité, en
      mesure de garantir la confidentialité et d’éviter l'accès illégitime de
      tiers ou d'un personnel non autorisé, conformément aux obligations
      législatives, sont mises en œuvre.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TITULAIRE ET RESPONSABLE DU TRAITEMENT DES DONNÉES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Le propriétaire du traitement des données est Lainox - Ali Group S.r.l. à
      Associé Unique, Via Schiaparelli, 15, 31029 – Vittorio Veneto (TV),
      Italie.<br />
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TYPES DE DONNÉES TRAITÉES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Données collectées et finalités <br />
      Les serveurs et les systèmes informatiques prévus pour le fonctionnement
      de ce site web acquièrent, au cours de leur exercice normal, certaines
      données dont la transmission est implicite pour l'usage des protocoles de
      communication internet. Il s'agit d'informations qui ne sont pas
      collectées pour être associées à des intérêts identifiés, mais qui de par
      leur nature peuvent, par le biais de traitements et d'associations avec
      des données détenues par des tiers, permettre d'identifier les
      utilisateurs.<br />
      Cette catégorie de données comprend les adresses IP ou les noms des
      ordinateurs utilisés par les utilisateurs qui se connectent au site, les
      adresses en notation URI (Uniform Resource Identifier) des ressources
      demandées, l'heure de la demande, la méthode utilisée pour soumettre la
      demande au serveur, la dimension du fichier de réponse, le code numérique
      indiquant l'état de la réponse donnée par le serveur (bonne, erreur, etc.)
      et d'autres paramètres relatifs au système d'exploitation et à
      l'environnement informatique de l'utilisateur.<br />
      Ces données sont utilisées dans le seul but de contrôler le bon
      fonctionnement du site. Les données peuvent être également utilisées par
      les forces de la sécurité publique pour le contrôle de responsabilité en
      cas d'éventuels délits informatiques du détriment du site.
    </p>
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Données fournies volontairement par l’utilisateur
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. à Associé Unique traite et utilise les
      informations personnelles seulement si fournies de manière facultative,
      explicite et volontaire et uniquement si légalement autorisé. La collecte
      des informations est obligatoire pour avoir accès au service Nabook ; pour
      utiliser le site Nabook, il est donc nécessaire de s’inscrire et d’ouvrir
      un compte. Le processus d’inscription comprend la communication de
      l’adresse e-mail, du nom et du prénom, du numéro de téléphone.<br />
      Toutes les informations (telles que le nom de la société, la marque du
      four, etc.) non exhaustives pour déterminer votre identité, peuvent être
      collectées, mais elles ne sont pas considérées comme des informations
      personnelles.<br />
      Les données collectées par le site pendant son fonctionnement ne sont
      utilisées que pour les finalités dont ci-dessus et ne sont conservées que
      pour la durée strictement nécessaire au déroulement desdites activités. En
      tout état de cause, elles ne seront jamais fournies à des tiers, sauf à
      des fins fonctionnelles à leur traitement, à moins qu’il ne s’agisse d’une
      demande légitime par l’autorité judiciaire et seulement dans les cas visés
      à la loi. Néanmoins, les données peuvent être fournies à des tiers si cela
      est nécessaire pour la fourniture d’un service spécifique demandé par
      l’utilisateur ou bien pour l’exécution de contrôles de sécurité ou
      d’optimisation du site. Les données utilisées à des fins de sécurité du
      site (blocage de tentatives d’endommagement du site) sont conservées
      pendant 7 jours ouvrables.<br />
      Pour la gestion de la phase d’enregistrement, le site utilise la procédure
      de «double confirmation» (double opt-in) apte aussi bien à vérifier que la
      personne qui effectue la demande d’inscription à travers le web correspond
      au propriétaire de l’adresse e-mail et qu’elle est en mesure d’accepter
      les conditions d’utilisation du service ; pour cela vous devrez confirmer
      pour compléter votre enregistrement en cliquant sur le lien qui vous sera
      envoyé à l’adresse e-mail fournie. Si vous n’enverrez pas le message de
      confirmation en cliquant sur le lien dans le délai établi votre demande
      d’enregistrement sera supprimée automatiquement de la base de données. Les
      données que vous fournissez pourront être collectées et utilisées à des
      fins de marketing uniquement si vous avez donné explicitement votre
      autorisation à cette utilisation. <br />
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Transfert des données à l’étranger
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Vos données à caractère personnel sont traitées dans l’Union Européenne
      ou, à la limite, dans des Pays qui sont en mesure d’assurer le même niveau
      de protection des données à caractère personnel garanti par le Règlement
      UE sur la protection des données à caractère personnel.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Lieu de traitement
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Les données collectées par le site sont traitées au siège du titulaire du
      traitement et au centre de données de l’hébergement web. L’hébergement web
      se trouve dans l’Espace Économique Européen et agir aux termes des normes
      européennes.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COOKIES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Pour de plus amples informations sur l'utilisation des cookies sur ce
      site, consultez notre note d'information détaillée – Politique des
      cookies.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Existence d’un processus de prise de décision automatisé, y compris le
      profilage
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. à Associé Unique n’adopte aucun processus de
      prise de décision automatisé, y compris le profilage, dont à l’article 22,
      alinéas 1 et 4, du Règlement UE 679/2016.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      DROITS DES PERSONNES CONCERNÉES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Vous pourrez exercer à tout moment, aux termes des articles 15 à 22 du
      Règlement UE 2016/679, le droit de :<br />
      a) demander la confirmation que des données à caractère personnel la
      concernant sont ou ne sont pas traitées ;<br />
      b) obtenir des indications sur les finalités du traitement, les catégories
      de données à caractère personnel, les destinataires ou les catégories de
      destinataires auxquels les données à caractère personnel ont été ou seront
      communiquées et, si possible, la période de conservation ;<br />
      c) obtenir la rectification et l’effacement des données ;<br />
      d) obtenir la limitation du traitement ;<br />
      e) obtenir la portabilité des données, à savoir obtenir les données dans
      un format structuré, couramment utilisé et lisible par machine, et les
      transmettre à un autre responsable du traitement sans que le responsable
      du traitement auquel les données à caractère personnel ont été
      communiquées y fasse obstacle ;<br />
      f) s’opposer au traitement à tout moment aussi en cas de traitement pour
      des fins de marketing indirect ;<br />
      g) s’opposer à la prise de décision automatisée relative aux personnes
      physiques, y compris le profilage ;<br />
      h) demander au responsable du traitement d’avoir accès aux données à
      caractère personnel, de les rectifier ou de les effacer ainsi qu’obtenir
      limitation du traitement, s’opposer au traitement et obtenir la
      portabilité des données ;<br />
      i) révoquer à tout moment le consentement sans préjudice de la licéité du
      traitement fondée sur le consentement donné avant la révocation ;<br />
      j) introduire une réclamation auprès d’une autorité de contrôle.<br />
      L’exercice de ces droits peut être en tout cas renvoyé, limité ou exclu en
      remettant immédiatement à la personne concerné une communication motivée
      dans la mesure où cela constitue une mesure temporaire nécessaire et
      proportionnée compte tenu des droits fondamentaux et des intérêts
      légitimes de la personne concerne afin de protéger les intérêts dont à
      l’alinéa 1, points a), b), d) et e). Dans ces cas, les droits de la
      personne concernée peuvent aussi être exercés per l’intermédiaire de
      l’autorité de contrôle. <br />
      Toute demande doit être adressée à Lainox - Ali Group S.r.l. à Associé
      Unique, Via Schiaparelli, 15, 31029 – Vittorio Veneto (TV), Italie, au
      numéro de téléphone +39 0438 9110 ou bien via e-mail à l’adresse
      privacy.cr&#64;aligroup.it.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LIMITES DE RESPONSABILITÉ
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. à Associé Unique est la seule responsable de la
      plate-forme Nabook pour ce qui est des relations avec l’utilisateur. Le
      fonctionnement technique du portail Nabook peut être géré pour le compte
      de Lainox - Ali Group S.r.l. à Associé Unique par un fournisseur tiers,
      qui sauvegarde toutes les données acquises comme partie intégrante du
      processus de gestion de Nabook. Ces données sont stockées dans un serveur
      situé physiquement au Royaume-Uni. Nabook n’est accessible que via
      internet : Lainox - Ali Group S.r.l. à Associé Unique n’est en aucun cas
      responsable de la connexion internet ni de l’éventuelle connexion des
      appareils. Lainox - Ali Group S.r.l. à Associé Unique s’engage à garantir
      la continuité du service et à minimiser les éventuels dysfonctionnements
      résultant de problèmes techniques ; toutefois, Lainox - Ali Group S.r.l. à
      Associé Unique ne peut pas garantir que le service est (ou reste)
      disponible, qu’il ne fait pas l’objet d’interruptions ou de
      dysfonctionnements, ni que les données et le contenu du site sont
      conservés au fil du temps ; par conséquent, Lainox - Ali Group S.r.l. à
      Associé Unique décline toute responsabilité pour les dommages de toute
      nature dérivant de l’utilisation du site ou de l’impossibilité d’y
      accéder. Lainox - Ali Group S.r.l. à Associé Unique n’est pas responsable
      d’éventuels dysfonctionnements dans les connexions aux sites externes qui
      peuvent être liés à ce site.<br />
      <strong
        >Lainox - Ali Group S.r.l. à Associé Unique n’est pas responsable
        d’éventuels dommages provoqués par l’utilisation des contenus (données,
        informations, logiciels, etc.) présents sur ce site. En particulier,
        Lainox - Ali Group S.r.l. à Associé Unique décline toute responsabilité
        pour l’utilisation d’ingrédients et/ou de produits cités dans les
        recettes ou d’autres données présentes à l’intérieur de Nabook par des
        personnes qui en sont allergiques ou qui en ont des réactions nuisibles.
      </strong>
      Les informations sur chaque ingrédient sont, en effet, fournies à titre
      d’exemple et vous en êtes entièrement responsable puisque vous pouvez
      modifier chaque propriété des fiches ingrédient disponibles. En
      particulier :<br />
      - Les calories sont fournies à titre d’exemple et sont toujours exprimées
      en kcal pour 100 grammes d’ingrédient, par portion (si indiqué), sauf
      indication contraire.<br />
      - Les prix, sont fournis à titre d’exemple et se réfèrent à une unité de
      l’unité de mesure principale définie pour chaque ingrédient, sauf
      indication contraire.<br />
      - Les allergènes indiqués dans la fiche ingrédient sont fournis à titre
      d’exemple et sont ceux généralement et probablement présents dans le type
      d’ingrédient<br />
      Lainox - Ali Group S.r.l. à Associé Unique se réserve le droit de modifier
      les contenus du site à tout moment et sans aucun préavis.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      CONNEXION À NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Toute forme de connexion à ce site doit être préalablement autorisée par
      Lainox - Ali Group S.r.l. à Associé Unique. Le lien profond (deep
      linking), c'est-à-dire l'utilisation non transparente, voire partielle, de
      ce site sur des sites de tiers, est interdit.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LICENCE D’UTILISATION DU PORTAIL NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. à Associé Unique est ravie de vous accorder une
      licence non exclusive mais personnelle pour l’utilisation actuellement
      libre du portail Nabook. Nous nous réservons le droit de débiter à
      l’avenir une somme pour l’utilisation de Nabook et d’annuler l’actuelle
      utilisation gratuite. En tout cas, la compensation d’une éventuelle future
      somme avec d’autres réclamations n’est pas admise, tout comme
      l’utilisation d’un droit de retenue, à moins que la demande ou le droit
      n’ait été reconnu par écrit.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TERMES CONTRACTUELS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Le présent accord sur les termes et les conditions d’utilisation de Nabook
      est illimité. Toutefois, il peut être librement résilié par l’une des deux
      parties avec un préavis de 4 semaines à compter de la date de
      communication de l’intention de résiliation. Lainox - Ali Group S.r.l. à
      Associé Unique sera donc autorisée à bloquer votre accès à Nabook et ne
      sera aucunement obligée de conserver les informations et/ou les données de
      votre compte.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      PROPRIÉTÉ INTELLECTUELLE, FICHIERS, DONNÉES ET AUTORISATIONS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lorsque vous utilisez Nabook en téléchargeant des recettes personnelles et
      en les personnalisant, vous fournissez un fichier (comme les cycles de
      cuisson personnelle pour les ingrédients personnels), un contenu (la
      description, la préparation ou les photos) et d’autres informations
      (ci-après : « votre contenu »). Votre contenu est, et restera, le vôtre.
      Les présents termes ne donnent aucun droit à Lainox - Ali Group S.r.l. à
      Associé Unique sur votre contenu à l’exception des droits limités qui
      autorisent Lainox - Ali Group S.r.l. à Associé Unique à offrir les
      services. Lainox - Ali Group S.r.l. à Associé Unique se chargera de
      protéger votre contenu contre tout accès non autorisé.<br />
      Lainox - Ali Group S.r.l. à Associé Unique a besoin de votre autorisation
      pour effectuer des opérations telles que la gestion de l’hébergement des
      fichiers, la sauvegarde et la synchronisation de votre contenu avec les
      appareils et les partager quand cela lui est demandé. Les services de
      Nabook peuvent offrir aussi des fonctions comme les photos miniatures, les
      aperçus des documents, les commentaires et les méthodes simples de
      commande de modification, de partage et de recherche de votre contenu. Ces
      fonctions et d’autres encore peuvent nécessiter l’accès, l’archivage et le
      scanning de la totalité ou d’une partie de votre contenu par le système.
      Vous autorisez Lainox - Ali Group S.r.l. à Associé Unique A effectuer ces
      opérations et cette autorisation s’étend aux affiliés et aux tierces
      parties fiables avec lesquels Lainox - Ali Group S.r.l. à Associé Unique
      collabore.<br />
      Sur la base du présent accord sur les termes et les conditions
      d’utilisation de Nabook, les droits sur vos informations d’utilisation non
      personnelles et/ou sur vos données, sous quelle forme que ce soit,
      envoyées à Lainox - Ali Group S.r.l. à Associé Unique. pour l’évaluation
      ou la publication sont accordés à Lainox - Ali Group S.r.l. à Associé
      Unique de manière gratuite, universelle, irrévocable, non exclusive et
      illimitée afin qu’elle puisse les utiliser sur un quelconque support, les
      traiter, les reproduire, les publier, les traduire et les diffuser. Vous
      nous accordé aussi le droit de transmettre, à notre tour, ces droits à des
      tiers. Lainox - Ali Group S.r.l. à Associé Unique se réserve le droit de
      modifier ou éliminer, ne serait-ce qu’en partie, les données transmises ou
      publiées sur Nabook ou bien hébergées ou sauvegardées dans nos serveurs.
      Lainox - Ali Group S.r.l. à Associé Unique n’a pas l’obligation de
      surveiller ou de contrôler ce que vous avez transmis et publié sur Nabook,
      qui ne peut être en aucun cas illégal ou illégitime et ne doit pas violer
      les droits d’autrui
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COPYRIGHT
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. à Associé Unique respecte la propriété
      intellectuelle d’autrui et nous vous demandons d’en faire autant. Lainox -
      Ali Group S.r.l. à Associé Unique répond aux allégations de violations du
      copyright à conditions qu’elles soient conformes à la loi et qu’elles
      soient communiquées aux termes des normes sur le copyright. Nous nous
      réservons le droit d’effacer ou de désactiver les contenus qui peuvent
      représenter des violations et de supprimer les comptes qui violent le
      copyright.<br />
      Tout droit sur le contenu (par exemple, textes, images, lay-out
      graphiques, logos, construction des pages, architecture du site, contenu
      textuel, etc.) est réservé aux termes des normes en vigueur. Le contenu
      des pages du site ne peut pas être, entièrement ou en partie, copié,
      reproduit, transféré, téléchargé, publié ou distribué d’aucune manière en
      l’absence de l'accord préalable écrit, sans préjudice de la possibilité de
      les stocker sur son propre ordinateur ou d’imprimer des extraits des pages
      de ce site, notamment pour les services expressément fournis par le site
      tels que les menus, les food cost et les listes de courses.<br />
      Toutes les marques et tous les logos appartenant à Lainox - Ali Group
      S.r.l. à Associé Unique présents sur les pages du site internet sont la
      propriété exclusive de Lainox - Ali Group S.r.l. à Associé Unique ; toute
      utilisation et/ou reproduction de ces derniers, quel que soit le but et le
      moyen, est expressément et sévèrement interdite et sera poursuivie aux
      termes des lois en vigueur en la matière. D’éventuels logos et marques
      appartenant à des tiers et visibles sur le site sont la propriété
      exclusive de leurs titulaires, qui ont autorisé l’utilisation par Lainox -
      Ali Group S.r.l. à Associé Unique : toute utilisation et/ou reproduction
      de ces derniers est sévèrement interdite.<br />
      <strong
        >Les présentes conditions d’utilisation, la politique sur le traitement
        des données et la politique sur les cookies représentent l’accord pris
        avec Lainox - Ali Group S.r.l. à Associé Unique pour l’utilisation de
        Nabook. L'éventuel non-respect de ces dispositions, sauf autorisation
        écrite explicite, engendrera des poursuites judiciaires civiles et
        pénales.</strong
      >
      <br /><br />
      Vittorio Veneto, 25/05/18
    </p>
  </div>

  <div *ngSwitchCase="'es'">
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      nabookplus.cloud – POLÍTICA WEB Y DECLARACIÓN DE PRIVACIDAD
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      En Lainox - Ali Group S.r.l. de Socio Único estamos contentos de su visita
      y del interés en nuestros productos.<br />
      Valoramos el hecho de que confíen en nosotros y por ello, deseamos
      comunicarle las modalidades de funcionamiento y gestión del sitio en
      relación con el tratamiento de los datos personales que recogemos para que
      pueda inscribirse en el portal Nabook.<br />
      Antes utilizar Nabook es necesario registrarse y abrir una cuenta; en
      particular, durante el registro le pedimos que nos confirme que ha leído y
      comprendido la Política de Privacidad y que nos dé su consentimiento,
      puesto que sin él no podrá usar el portal Nabook. Los datos recogidos no
      serán vendidos ni facilitados a terceros. El tratamiento de los datos se
      basa en principios de licitud y lealtad conforme a las normativas
      aplicables vigentes, en particular la declaración se formula de
      conformidad con los artículos 13 y 14 del RGPD – Reglamento UE 2016/679
      para quienes interactúan con los servicios web accesibles por vía
      telemática a través de las páginas de este sitio internet. El portal
      Nabook utiliza cookies.<br />
      La presente política de privacidad se aplica exclusivamente a las
      actividades en línea de este sitio y es válida para los
      visitantes/usuarios del sitio web. No se aplica a los datos que puedan
      recogerse a través de otros canales que no sean este sitio web.<br />
      La finalidad de la declaración de privacidad es proporcionar la mayor
      transparencia en relación con los datos que el sitio recoge y cómo los usa
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      DECLARACIÓN DE PRIVACIDAD SOBRE EL TRATAMIENTO DE DATOS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Los tratamientos relacionados con los servicios web de este sitio tienen
      lugar en la sede de Lainox - Ali Group S.r.l. de Socio Único y son
      tratados por personal encargado especialmente del tratamiento y por los
      eventuales encargados de las operaciones de mantenimiento.<br />
      Con el fin de prevenir la pérdida de los datos, así como usos ilícitos o
      no correctos, se llevan a cabo medidas de seguridad específicas a fin de
      garantizar la privacidad y para evitar el acceso indebido de terceros o
      personal no autorizado, de conformidad con las obligaciones legales.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      El Responsable del tratamiento de los datos es Lainox - Ali Group S.r.l.
      de Socio Único, Via Schiaparelli, 15, 31029 – Vittorio Veneto (TV),
      Italia.<br />
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TIPOS DE DATOS TRATADOS<br />Datos recogidos y finalidad
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Los servidores y los sistemas informáticos encargados del funcionamiento
      de este sitio web adquieren, en el curso de su normal ejercicio, algunos
      datos cuya transmisión está implícita en el uso de los protocolos de
      comunicación Internet. Se trata de datos que se recogen para asociarlos a
      interesados identificados, pero que por su misma naturaleza podrían
      permitir identificar a los usuarios a través de elaboraciones y
      asociaciones con datos poseídos por terceros.<br />
      En esta categoría de datos entran las direcciones IP o los nombres de
      dominio de los ordenadores utilizados por los usuarios que se conectan al
      sitio, las direcciones en notación URI (Uniform Resource Identifier) de
      los recursos solicitados, el horario de la solicitud, el método utilizado
      al presentar la solicitud al servidor, la dimensión del archivo obtenido
      como respuesta, el código numérico que indica el estado de la respuesta
      dada por el servidor (correcto, error, etc.) y otros parámetros relativos
      al sistema operativo y al entorno informático del Usuario.<br />
      Estos datos se utilizan con la finalidad de comprobar que el sitio
      funciona correctamente. Los datos podrían además ser utilizados por las
      fuerzas de Seguridad Pública para verificar responsabilidades en caso de
      eventuales delitos informáticos en perjuicio del sitio.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Datos suministrados voluntariamente por el Usuario
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. de Socio Único procesa y usa los datos
      personales solo si se han puesto a disposición de modo facultativo,
      explícito y voluntario y solo si está legalmente permitido. La recogida de
      datos es obligatoria para poder acceder al servicio Nabook, por lo tanto,
      para poder utilizar el sitio Nabook es preciso inscribirse y abrir una
      cuenta. Como parte del proceso de registro se recogen la dirección de
      correo electrónico, el nombre y apellido y el número de teléfono. <br />
      Los datos (por ejemplo, el nombre de la sociedad, la marca del horno) que
      podrían no ser suficientes para determinar su identidad pueden recogerse,
      pero no serán considerados información de carácter personal. <br />
      Los datos que se recogen a través del sitio durante su funcionamiento se
      utilizan exclusivamente para los fines antes citados y se conservan por el
      periodo de tiempo estrictamente necesario para realizar las actividades
      indicadas. En todo caso, los datos personales detectados por el sitio no
      serán suministrados a terceros, salvo para los fines funcionales para su
      tratamiento, a menos que se trate de una solicitud legítima por parte de
      las autoridades judiciales y solo en los casos previstos por la ley. Los
      datos podrán suministrarse a terceros si es necesario para prestar un
      servicio específico solicitado por el Usuario o para la ejecución de
      controles de seguridad y de optimización del sitio. Los datos que se
      utilizan para fines de seguridad del sitio (bloqueo de intentos de dañar
      el sitio) se conservan por 7 días laborables.<br />
      Para la gestión del proceso de registro, el sitio utiliza el procedimiento
      de “doble confirmación” (double opt-in) para verificar que la persona que
      efectúa la solicitud de inscripción desde la web sea el titular real de la
      dirección de correo electrónico y dé un consentimiento expreso y explícito
      adicional para aceptar las condiciones de uso del servicio. Esto significa
      que, para completar su registro, deberá confirmarlo haciendo clic en el
      enlace que le será enviado a la dirección de correo electrónico que nos ha
      suministrado.<br />
      Si no manda el mensaje de confirmación haciendo clic en el enlace dentro
      de un cierto tiempo, su solicitud de registro será eliminada
      automáticamente de la base de datos. Los datos que usted ha facilitado
      podrán ser recogidos e utilizados con fines de marketing sólo cuando usted
      haya dado su consentimiento explícito para dicha utilización.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Transferencia de datos al extranjero.
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Sus datos personales se tratan en el ámbito de la Unión Europea o en
      países que garantizan el mismo nivel de protección de datos personales del
      Reglamento UE sobre la protección de datos personales.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Lugar del tratamiento
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Los datos recogidos por el sitio se tratan en la sede del Responsable del
      Tratamiento y en los centros de datos del web hosting. El web hosting se
      encuentra en el Espacio Económico Europeo y actúa de conformidad con las
      normas europeas.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COOKIES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Para mayor información relativa al uso de las cookies en este sitio puede
      consultar nuestra declaración – Política de Cookies.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Existencia de un proceso de decisión automatizado, incluida la elaboración
      de perfiles
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. de Socio Único no adopta ningún proceso de
      decisión automatizado que comprenda la elaboración de perfiles, al que se
      refiere el artículo 22, apartados 1 y 4 del Reglamento UE n.º 679/2016.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      DERECHOS DE LOS INTERESADOS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      En todo momento, Ud. podrá ejercer, en virtud de los artículos 15 a 22 del
      Reglamento UE n.º 679/2016, el derecho de:<br />
      a) solicitar la confirmación de la existencia de sus datos personales;<br />
      b) obtener las indicaciones relacionadas con los fines del tratamiento,
      las categorías de datos personales, los destinatarios con las categorías
      de destinatarios a los cuales los datos personales se han comunicado o
      serán comunicados y, en lo posible, el período de conservación;<br />
      c) obtener la rectificación y la cancelación de los datos;<br />
      d) obtener la limitación del tratamiento;<br />
      e) obtener la portabilidad de los datos, es decir recibirlos de un
      responsable del tratamiento, en un formato estructurado, de uso común y
      legible por un dispositivo automático y transmitirlos a otro responsable
      del tratamiento sin obstáculos;<br />
      f) oponerse al tratamiento en cualquier momento e incluso en el caso de
      tratamiento para fines de marketing directo;<br />
      g) oponerse a un proceso de decisión automatizado relativo las personas
      físicas, que comprende la elaboración de perfiles.<br />
      h) pedir al titular del tratamiento el acceso a los datos personales y la
      rectificación o la eliminación de los mismos o la limitación del
      tratamiento que le concierne o de oponerse a su tratamiento, además del
      derecho a la portabilidad de los datos;<br />
      i) revocar el consentimiento en cualquier momento sin perjudicar la
      licitud de tratamiento basada en el consentimiento prestado antes de la
      revocación;<br />
      j) presentar reclamaciones a las autoridades de control.<br />
      El ejercicio de tales derechos puede, en todo caso, retrasarse, limitarse
      o excluirse con comunicación motivada y enviada sin demora al interesado,
      por el tiempo y dentro de los límites en que constituya una medida
      necesaria y proporcionada, teniendo en cuenta los derechos fundamentales y
      los legítimos intereses del interesado, a fin de proteger los intereses a
      los que se refiere el apartado 1, letras a), b), c), d) y e). En tales
      casos, los derechos del interesado pueden ser ejercidos incluso a través
      del Supervisor.<br />
      Puede dirigir sus solicitudes a Lainox – Aligroup S.r.l. de Socio Único,
      Via Schiaparelli, 15, 31029 – Vittorio Veneto (TV), Italia, al número de
      teléfono +39 0438 9110 o por medio de correo electrónico a la dirección
      privacy.cr&#64;aligroup.it
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LÍMITES DE RESPONSABILIDAD
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. de Socio Único es la única responsable de la
      plataforma Nabook en términos de relación con usted. El funcionamiento
      técnico del portal Nabook puede ser gestionado por cuenta de Lainox - Ali
      Group S.r.l. de Socio Único por parte de un proveedor tercero, que
      memoriza todos los datos adquiridos como parte del proceso de gestión de
      Nabook, datos que son memorizados en servidores físicamente ubicados en el
      Reino Unido. Nabook es accesible solo a través de Internet: Lainox - Ali
      Group S.r.l. de Socio Único no es en ningún modo responsable de la
      conexión internet ni de la eventual conexión de los equipos. Lainox - Ali
      Group S.r.l. de Socio Único se propone garantizar la continuidad del
      servicio y reducir al mínimo las eventuales disfunciones imputables a
      problemas técnicos. No puede, sin embargo, garantizar que el servicio esté
      o permanezca disponible, ni que no esté sujeto a interrupciones o mal
      funcionamiento, ni que los datos y el contenido del sitio se mantengan a
      lo largo del tiempo, por lo tanto Lainox - Ali Group S.r.l. de Socio Único
      no asume ninguna responsabilidad por los daños de cualquier naturaleza que
      puedan derivar del uso del sitio o de la imposibilidad de acceder a este.
      Ni responde por posibles averías de las conexiones a los sitios externos
      que podrán ser asociados a este sitio.
      <br />
      <strong
        >Lainox - Ali Group S.r.l. de Socio Único no responde por los eventuales
        daños causados por el uso de los contenidos (datos, información,
        software, etc.) de este sitio. En particular, Lainox - - Ali Group
        S.r.l. de Socio Único no asume ninguna responsabilidad por el uso de los
        ingredientes y/o productos indicados en las recetas y en otros
        contenidos de Nabook por personas que sean alérgicas o tengan reacciones
        de algún modo dañinas provenientes de estos.</strong
      >
      La información sobre los ingredientes se da sólo como referencia, y usted
      tiene plena responsabilidad, pudiendo modificar cada propiedad de las
      fichas de ingredientes disponibles. En particular:<br />
      - Las calorías, solo indicativas, están expresadas en kcal y se refieren a
      100 gramos de ingrediente, por porción donde esté indicado, salvo
      especificación diferente.<br />
      - Los precios son indicativos, se refieren a una unidad de la unidad de
      medida principal definida por el ingrediente individual, salvo
      especificación diferente.<br />
      - Los alérgenos, solo indicativos, señalados en la ficha ingrediente son
      los comúnmente y potencialmente presentes en el tipo de ingrediente.<br />
      Lainox - Ali Group S.r.l. de Socio Único se reserva modificar los
      contenidos del sitio en cualquier momento y sin previo aviso.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      CONEXIONES A NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Cualquier forma de conexión al presente sitio debe ser previamente
      autorizada por Lainox - Ali Group S.r.l. de Socio Único. Está prohibido el
      enlace profundo (deep linking), es decir, el uso no transparente de este
      sitio o de sus partes en sitios de terceros.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LICENCIA DE USO DEL PORTAL NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. de Socio Único se complace en concederle una
      licencia no exclusiva y no transferible para el uso, actualmente libre,
      del portal Nabook. Se reserva el derecho de cobrar una tarifa para el uso
      de Nabook en el futuro y anular el uso gratuito actual. En todo caso, no
      está permitida la compensación de una eventual futura tarifa con otras
      reclamaciones, ni utilizar ningún derecho de retención a menos que la
      solicitud o el derecho hayan sido reconocidos por escrito.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TÉRMINOS CONTRACTUALES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      El presente acuerdo sobre los términos y las condiciones de uso de Nabook
      tiene una validez indefinida. Sin embargo, este puede ser rescindido
      libremente por una de las dos partes con un período de aviso previo de 4
      semanas a partir de la fecha de comunicación de la voluntad de la
      resolución. Lainox - Ali Group S.r.l. de Socio Único estará por lo tanto
      autorizada a bloquearle el acceso a Nabook y no tendrá ninguna obligación
      de mantener la información y/o los datos específicamente presentes en su
      cuenta.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      PROPRIEDAD INTELECTUAL, ARCHIVOS, DATOS Y AUTORIZACIONES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Cuando utiliza Nabook cargando recetas personales y personalizándolo en
      varias formas, nos proporciona archivos (por ejemplo, ciclos de cocción
      personales, o ingredientes personales), contenidos (por ejemplo,
      descripciones, procedimientos o fotografías) y además (a continuación,
      definidos como "Su contenido"). Su contenido es y será de su propiedad.
      Estos términos no conceden a Lainox - Ali Group S.r.l. de Socio Único
      ningún derecho sobre su contenido a excepción de los derechos limitados
      que permiten que Lainox - Ali Group S.r.l. de Socio Único ofrezca los
      servicios. Lainox - Ali Group S.r.l. de Socio Único se encargará en todo
      momento de proteger su contenido de accesos no autorizados.<br />
      Lainox - Ali Group S.r.l. de Socio Único necesita su consentimiento para
      realizar operaciones como gestionar el alojamiento de los archivos,
      realizar una copia de seguridad, sincronizar su contenido con los equipos
      y compartirlos cuando se requiere hacerlo. Los servicios de Nabook pueden
      ofrecer además funciones como miniaturas de foto, vistas preliminares de
      documentos, añadir comentarios y modos simples para ordenar, modificar,
      compartir y buscar contenidos. Estas y otras funciones podrían requerir
      acceder, archivar y escanear los contenidos de forma total o parcial por
      parte del sistema. Usted da su consentimiento a Lainox - Ali Group S.r.l.
      de Socio Único para realizar tales operaciones y esto se extiende a
      afiliados y a terceros fiables con los cuales Lainox - Ali Group S.r.l. de
      Socio Único colabora.<br />
      En base al presente acuerdo en cuanto a los términos y las condiciones de
      uso de Nabook, los derechos sobre sus datos de uso no personales y/o sobre
      los datos, en cualquier forma, que se envían a Lainox - Ali Group S.r.l.
      de Socio Único para su evaluación o publicación se otorgan a Lainox - Ali
      Group S.r.l. de Socio Único de forma gratuita, universal, irrevocable y no
      exclusiva por tiempo indefinido, para que pueda utilizarlos en cualquier
      soporte, elaborarlos, reproducirlos, publicarlos, traducirlos y
      difundirlos. Nos concede además los derechos para transmitir a su vez
      tales derechos a terceros. Lainox - Ali Group S.r.l. de Socio Único se
      reserva el derecho de modificar o eliminar, incluso parcialmente, lo que
      se ha transmitido o publicado en Nabook o está alojado o memorizado en
      nuestros servidores. Lainox - Ali Group S.r.l. de Socio Único no está
      obligado a monitorizar o vigilar lo que usted transmite o publica en
      Nabook, que en todo caso no puede ser ilegal ni ilegítimo y no debe violar
      los derechos de otros.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COPYRIGHT
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. de Socio Único - respeta la propiedad
      intelectual de los demás y le pedimos hacer lo mismo. Lainox - Ali Group
      S.r.l. de Socio Único responde a avisos relativos a presuntas violaciones
      del copyright, si son conformes a la ley y si nos son comunicados a través
      de las Normas sobre el copyright. Nos reservamos el derecho de eliminar o
      desactivar contenidos que representan presuntas violaciones y de eliminar
      las cuentas que violan el copyright.<br />
      Todo derecho sobre los contenidos (solo a título ejemplificativo textos,
      imágenes, diseños gráficos, logotipos, construcción de las páginas,
      arquitectura del sitio, contenidos textuales, etc.) es confidencial
      conforme a la normativa vigente. Los contenidos de las páginas del sitio
      no pueden ser - ni totalmente ni parcialmente - copiados, reproducidos,
      transferidos, cargados, publicados o distribuidos de cualquier modo sin
      previo consentimiento escrito, a excepción de la posibilidad de
      almacenarlos en el propio ordenador o de imprimir extractos de las páginas
      de este sitio, especialmente para aquellos servicios que el sitio
      proporciona como, solo a título ejemplificativo, los menús, los costes de
      los alimentos, y las listas de la compra.<br />
      Las marcas y los logotipos pertenecientes a Lainox - Ali Group S.r.l. de
      Socio Único que aparecen en las páginas del sitio web son propiedad
      exclusiva de Lainox - Ali Group S.r.l. de Socio Único; cualquier uso y/o
      reproducción de los mismos, para un fin cualquiera o por cualquier medio,
      queda prohibido de forma terminante y explícita y será perseguido según
      las leyes competentes en la materia. Las marcas y los logotipos
      pertenecientes a terceros que aparecen en el sitio son propiedad exclusiva
      de sus titulares, quienes han dado su consentimiento para que sean
      utilizados por Lainox - Ali Group S.r.l. de Socio Único: cualquier otro
      uso y/o reproducción también queda terminantemente prohibido.<br />
      <strong
        >Las presentes Condiciones de uso, la Política sobre el Tratamiento de
        Datos y la Política de Cookies constituyen el acuerdo entre usted y
        Lainox - Ali Group S.r.l. de Socio Único en lo que respecta al uso di
        Nabook. El eventual incumplimiento de las presentes disposiciones, salvo
        autorización explícita por escrito, será perseguida en las competentes
        sedes judiciales civiles y penales.</strong
      >
      <br /><br />
      Vittorio Veneto, 25/05/18
    </p>
  </div>

  <div *ngSwitchCase="'de'">
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      nabookplus.cloud - WEB POLICY UND DATENSCHUTZERKLÄRUNG
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Wir von Lainox - Ali Group S.r.l. mit Alleingesellschafter freuen uns über
      Ihren Besuch auf unserer Website und Ihr Interesse an unseren
      Produkten.<br />
      Für uns ist es sehr wichtig, dass Sie uns Ihr Vertrauen schenken und daher
      möchten wir Ihnen die Funktions- und Verwaltungsweisen der Website auch
      hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten, die wir für
      Ihre Registrierung im Nabook-Portal sammeln, erläutern.<br />
      Bevor Sie Nabook verwenden, ist es nötig, dass Sie sich registrieren und
      ein Konto erstellen. Insbesondere werden Sie während der Registrierung
      aufgefordert, zu bestätigen, dass Sie die Datenschutzerklärung gelesen und
      verstanden haben und ausdrücklich in die Datenverarbeitung einwilligen.
      Ohne diese Einwilligung kann das Nabook-Portal nicht benutzt werden. Die
      gesammelten Daten werden weder verkauft, noch Dritten zur Verfügung
      gestellt. Die Verarbeitung Ihrer Daten stützt sich immer auf die
      Grundsätze der Rechtmäßigkeit und von Treu und Glauben unter Befolgung
      aller hierbei geltenden Vorschriften. Insbesondere gilt die
      Datenschutzerklärung im Sinne von Art. 13 und 14 der DSGV - Verordnung
      (EU) 2016/679 auch für die Personen, die mit den telematisch über die
      Seiten dieses Portals zugänglichen Webdiensten interagieren. Das Portal
      Nabook verwendet Cookies.<br />
      Diese Datenschutzerklärung bezieht sich nur auf die Online-Aktivitäten
      dieser Website und gilt für ihre Besucher/Nutzer. Sie findet keine
      Anwendung bei Informationen, die über andere Kanäle als über diese Website
      erfasst werden.<br />
      Zweck der Datenschutzerklärung ist es, maximale Transparenz über die von
      der Website gesammelten Informationen und wie diese verwendet werden, zu
      gewährleisten.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      DATENSCHUTZERKLÄRUNG ZUR DATENVERARBEITUNG
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Die mit den Webdiensten dieser Website verbundene Datenverarbeitung
      erfolgt im Firmensitz von Lainox - Ali Group S.r.l. mit
      Alleingesellschafter und wird ausschließlich von eigens dafür beauftragtem
      Personal und von für die Wartung möglicherweise beauftragten Personen
      ausgeführt.<br />
      Um den Verlust von Daten sowie eine unrechtmäßige oder unsachgemäße
      Verwendung zu verhindern, wurden im Rahmen der gesetzlichen Bestimmungen
      spezielle Sicherheitsmaßnahmen zur Wahrung der Vertraulichkeit und zur
      Vermeidung von unberechtigtem Zugriff durch Dritte oder unbefugte Personen
      getroffen.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      VERANTWORTLICHER UND AUFTRAGSVERARBEITER
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Verantwortlicher der Datenverarbeitung ist Lainox - Ali Group S.r.l. mit
      Alleingesellschafter, Via Schiaparelli 15, 31029 – Vittorio Veneto (TV),
      Italien.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      ARTEN DER VERARBEITETEN DATEN<br />Erhobene Daten und Zwecke
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Die Server und Informatiksysteme, die dafür sorgen, dass diese Website
      funktioniert, sammeln im Laufe ihres normalen Betriebs einige Daten, deren
      Übertragung bei der Verwendung der Internetkommunikationsprotokolle
      implizit ist. Es handelt sich um Informationen, die nicht erhoben werden,
      um sie mit identifizierten betroffenen Personen zu verknüpfen, die jedoch
      aufgrund ihrer Natur durch die Verarbeitungen und Verknüpfungen mit Daten,
      die in den Händen Dritter sind, die Identifikation des Nutzers ermöglichen
      könnten.<br />
      Unter diese Datenkategorie fallen die IP-Adressen oder die Domänennamen
      der von den Nutzern, die sich mit der Website verbinden, verwendeten
      Computer, die URI-Adressen (Uniform Resource Identifier) der angeforderten
      Ressourcen, der Zeitpunkt der Anfrage, die verwendete Methode bei der
      Anfrage am Server, die Größe der Antwortdatei, der numerische Code, der
      den vom Server gegebenen Status der Anfrage (erfolgreich, Fehler usw.)
      angibt und andere Parameter hinsichtlich des Betriebssystems und der
      Computerumgebung des Nutzers.<br />
      Diese Daten werden ausschließlich zur Überprüfung des ordnungsgemäßen
      Funktionierens der Website verwendet. Die Daten könnten außerdem von den
      Organen zur Aufrechterhaltung der öffentlichen Sicherheit verwendet
      werden, um bei Cyber-Straftaten zum Schaden der Website, die
      Verantwortlichen zu ermitteln.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Vom Benutzer freiwillig zur Verfügung gestellte Daten
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Die Lainox - Ali Group S.r.l. mit Alleingesellschafter verarbeitet und
      nutzt personenbezogene Daten nur dann, wenn sie fakultativ, ausdrücklich
      und freiwillig zur Verfügung gestellt wurden und nur, wenn es rechtlich
      zulässig ist. Das Mitteilen von Informationen ist unabdingbar, um den
      Service von Nabook nutzen zu können. Daher ist für die Nutzung der Nabook
      Website eine Registrierung und das Erstellen eines Kontos erforderlich.
      Als Teil des Registrierungsvorganges werden die E-Mail-Adresse, der Vor-
      und Zuname und die Telefonnummer gespeichert. Alle Informationen (wie zum
      Beispiel der Firmenname oder die Marke des Ofens), die möglicherweise
      nicht ausreichen, um Ihre Identität festzustellen, können gesammelt
      werden, gelten jedoch nicht als personenbezogene Daten. Die von der
      Website während ihres Betriebs gesammelten Daten werden ausschließlich für
      die oben genannten Zwecke genutzt und für die zur Durchführung der
      angegebenen Aktivitäten unbedingt erforderliche Zeit gespeichert. In
      keinem Fall werden personenbezogene Daten, die über die Website erhoben
      werden, an Dritte weitergegeben, es sei denn für die für ihre Verarbeitung
      vorgesehenen Zwecke, außer bei einer rechtmäßigen Aufforderung seitens der
      Justizbehörden und nur in gesetzlich vorgesehenen Fällen.Die Daten können
      jedoch an Dritte weitergegeben werden, wenn dies für die Erbringung eines
      bestimmten, vom Nutzer gewünschten Dienstes oder zur Durchführung von
      Sicherheitskontrollen oder zur Optimierung der Website erforderlich ist.
      Die für die Sicherheit der Website verwendeten Daten (Blockierung von
      Versuchen, die Website zu schädigen) werden 7 Werktage lang gespeichert.
      Die Website verwendet für den Registriervorgang das Verfahren der
      "Doppelten Bestätigung" (Double opt-in), über das sowohl überprüft wird,
      ob die Person, die sich auf der Website registrieren möchte, der
      tatsächliche Inhaber der E-Mail-Adresse ist, und über das auch eine
      weitere ausdrückliche Zustimmung zu den Nutzungsbedingungen des Dienstes
      bereitgestellt wird, was bedeutet, dass Sie zur Vervollständigung Ihrer
      Registrierung zwecks Bestätigung auf den Link klicken müssen, den wir an
      Ihre uns mitgeteilte E-Mail-Adresse gesendet haben. Wenn Sie nicht
      innerhalb eines bestimmten Zeitraumes den Link zum Absenden der
      Bestätigungsnachricht anklicken, wird Ihre Registrierungsanfrage
      automatisch aus unserer Datenbank gelöscht. Die von Ihnen angegebenen
      Daten können nur dann für Marketingzwecke gesammelt und genutzt werden,
      wenn Sie dieser Nutzung ausdrücklich zugestimmt haben.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Datenübermittlung an das Ausland
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Ihre personenbezogenen Daten werden innerhalb der Europäischen Union oder
      höchstens in Ländern verarbeitet, die das gleiche Schutzniveau wie die
      EU-Verordnung zum Schutz personenbezogener Daten gewährleisten.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Ort der Verarbeitung
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Die von der Website gesammelten Daten werden am Sitz des Verantwortlichen
      und im Webhosting-Rechenzentrum verarbeitet. Das Webhosting befindet sich
      im Europäischen Wirtschaftsraum und richtet sich nach den europäischen
      Standards.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COOKIES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Für Informationen zur Verwendung von Cookies auf dieser Website können Sie
      unsere erweiterte Cookie-Erklärung konsultieren.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Vorhandensein einer automatisierten Entscheidungsfindung, einschließlich
      Profiling
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. mit Alleingesellschafter nutzt im Sinne von
      Artikel 22 Absätze 1 und 4 der Verordnung (EU) Nr. 679/2016 keine
      automatisierten Entscheidungsfindung, einschließlich Profiling.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      RECHTE DER BETROFFENEN PERSONEN
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Gemäß Artikel 15 bis 22 der EU-Verordnung Nr. 2016/679 können Sie
      jederzeit von folgenden Rechten Gebrauch machen:<br />
      a) eine Bestätigung darüber zu verlangen, ob ihre personenbezogenen Daten
      vorhanden sind oder nicht;<br />
      b) Informationen über den Zweck der Verarbeitung, die Kategorien
      personenbezogener Daten, die Empfänger oder Kategorien von Empfängern,
      denen die personenbezogenen Daten offengelegt wurden oder noch offengelegt
      werden, und, soweit möglich, die Dauer der Speicherung zu erhalten;<br />
      c) die Berichtigung und Löschung der Daten zu erhalten;<br />
      d) die Einschränkung der Verarbeitung zu erreichen;<br />
      e) die Übertragbarkeit der Daten, d. h. den Empfang der Daten von einem
      Verantwortlichen, in einem strukturierten Format, das üblicherweise von
      einem automatischen Gerät verwendet und gelesen werden kann, zu erreichen
      und die Daten ungehindert an einen anderen Verantwortlichen zu
      übermitteln;<br />
      f) der Verarbeitung jederzeit und auch bei Verarbeitung zu
      Direktwerbungszwecken zu widersprechen;<br />
      g) Widerspruch gegen einen automatisierten Entscheidungsfindungsprozess in
      Bezug auf natürliche Personen, einschließlich Profiling einzulegen.<br />
      h) vom Verantwortlichen Zugriff auf die ihn betreffenden personenbezogenen
      Daten zu erlangen, sie zu berichtigen, zu löschen bzw. ihre Verarbeitung
      einzuschränken oder Widerspruch gegen ihre Verarbeitung einzulegen sowie
      das Recht auf Datenübertragbarkeit zu verlangen;<br />
      i) die Einwilligung jederzeit widerrufen zu können, unbeschadet der
      Rechtmäßigkeit der Verarbeitung auf der Grundlage der vor dem Widerruf
      erteilten Einwilligung;<br />
      j) eine Beschwerde bei einer Aufsichtsbehörde einzureichen.<br />
      Die Ausübung dieser Rechte kann in jedem Fall durch eine begründete und
      unverzügliche Mitteilung an die betroffene Person so lange und in dem
      Maße, wie sie eine notwendige und verhältnismäßige Maßnahme darstellt,
      verzögert, eingeschränkt oder ausgeschlossen werden, soweit dies unter
      Berücksichtigung der Grundrechte und der berechtigten Interessen der
      betroffenen Person notwendig und verhältnismäßig ist, um die in Absatz 1
      Buchstaben a), b), d) und e) genannten Interessen zu wahren. In diesen
      Fällen können die Rechte der betroffenen Person auch durch den
      Datenschutzbeauftragten ausgeübt werden. <br />
      Wenn Sie dieses Recht in Anspruch nehmen wollen, können Sie an Lainox -
      Ali Group S.r.l. mit Alleingesellschafter, Via Schiaparelli, 15, 31029 –
      Vittorio Veneto (TV), Italien schreiben, unter der Telefonnummer+39 0438
      9110 anrufen oder eine E-Mail an privacy.cr&#64;aligroup.it schicken.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      HAFTUNGSBESCHRÄNKUNG
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Im Rahmen der Beziehung mit Ihnen ist einzig Lainox - Ali Group S.r.l. mit
      Alleingesellschafter für die Nabook-Plattform verantwortlich. Die
      technische Funktion des Nabook-Portals kann für Lainox - Ali Group S.r.l.
      mit Alleingesellschafter von einem Drittanbieter geleistet werden, der
      alle erhaltenen Daten als Teil des Verwaltungsprozesses von Nabook
      speichern kann, Daten, die von Servern, die physisch im Vereinigten
      Königreich stehen, gespeichert werden. Nabook ist nur über Internet
      erreichbar. Lainox - Ali Group S.r.l. mit Alleingesellschafter trägt die
      Verantwortung weder für die Internetverbindung noch für die Verbindung mit
      den Geräten. Lainox - Ali Group S.r.l. mit Alleingesellschafter
      beabsichtigt, die Kontinuität des Dienstes zu gewährleisten und eventuelle
      Fehlfunktionen, die technischen Problemen zuzuschreiben sind, auf ein
      Minimum zu reduzieren. Dennoch kann das Unternehmen weder garantieren,
      dass der Dienst zur Verfügung steht oder zur Verfügung stehen bleibt, noch
      dass er keinen Störungen oder Fehlfunktionen ausgesetzt ist oder dass die
      Daten und der Inhalt der Website auf Dauer erhalten bleiben. Daher
      übernimmt Lainox - Ali Group S.r.l. mit Alleingesellschafter keinerlei
      Haftung für Schäden jedweder Art, die durch die Nutzung dieser Site oder
      die Unmöglichkeit, Zugang zu erhalten, entstehen. Außerdem haftet das
      Unternehmen auch nicht für Funktionsstörungen bei den Verbindungen zu
      externen Websites, die mit dieser Site verbunden werden können.<br />
      <strong
        >Lainox - Ali Group S.r.l. mit Alleingesellschafter haftet nicht für
        eventuelle Schäden, die durch die Nutzung der Inhalte (Daten,
        Informationen, Software, usw.) dieser Website entstehen. Insbesondere
        übernimmt Lainox - Ali Group S.r.l. mit Alleingesellschafter keine
        Haftung bei der Verwendung von Zutaten und/oder Produkten, die in den
        Rezepten oder in anderen auf Nabook vorhandenen Daten angegeben sind und
        durch die Personen zu Schaden kommen könnten, die dagegen allergisch
        sind oder in irgendeiner Form für sie schädliche Reaktionen verursachen.
      </strong>
      Die Informationen über die einzelnen Zutaten sind als Richtwerte zu
      verstehen und Sie selbst tragen die volle Verantwortung hierfür, da sie
      jede einzelne Eigenschaft auf den verfügbaren Zutatenblättern abändern
      können. Vor allem:<br />
      - Die Kalorien sind als reine Richtwerte in kcal angegeben und beziehen
      sich auf jeweils 100 Gramm der Zutaten oder auf eine Portion, falls nicht
      ausdrücklich anders angegeben.<br />
      - Die Preise sind unverbindliche Richtpreise und beziehen sich, falls
      nicht anders angegeben, auf eine Einheit der für jede einzelne Zutat
      festgelegten Maßeinheit.<br />
      - Bei den in den Zutatenverzeichnissen nur hinweisgebend aufgeführten
      Allergenen handelt es sich um die <br />
      üblicherweise und potenziell in der jeweiligen Art Zutat enthaltenen
      Allergene.<br />
      Lainox - Ali Group S.r.l. mit Alleingesellschafter behält sich das Recht
      vor, die Inhalte der Website jederzeit und ohne Vorankündigung zu ändern.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      VERBINDUNGEN ZU NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Jede Verbindung zu dieser Website muss vorher von Lainox - Ali Group
      S.r.l. mit Alleingesellschafter genehmigt worden sein. Das so genannte
      Deep-linking bzw. der nicht transparente Gebrauch dieser Website, auch nur
      von Teilen dieser, auf Websites Dritter ist nicht erlaubt.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      NUTZUNGSLIZENZ FÜR DAS NABOOK-PORTAL
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. mit Alleingesellschafter freut sich, Ihnen eine
      nicht exklusive und nicht übertragbare Lizenz für die derzeit freie
      Nutzung des Nabook-Portals zu gewähren. Sie behält sich vor, in Zukunft
      eine Gebühr für die Nutzung von Nabook zu erheben und die momentan
      kostenlose Nutzung aufzuheben. In jedem Fall ist eine Aufrechnung einer
      eventuell zukünftig anfallenden Gebühr mit anderen Forderungen nicht
      zulässig, wie auch jedwede Einbehaltung unzulässig ist, es sei denn, die
      Forderung oder das Recht wurden vorher schriftlich anerkannt.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      VERTRAGSBEDINGUNGEN
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Diese Vereinbarung über die Nutzungsbedingungen von Nabook gilt für
      unbestimmte Zeit. Sie kann allerdings mit einer Frist von 4 Wochen ab dem
      Datum der Mitteilung über die Kündigungsabsicht von einer der beiden
      Vertragsparteien frei aufgelöst werden. Lainox - Ali Group S.r.l. mit
      Alleingesellschafter wird daher befugt sein, Ihnen den Zugang zu Nabook zu
      sperren und wird nicht verpflichtet sein, die speziell in Ihrem Konto
      enthaltenen Informationen und/oder Daten aufzubewahren.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      GEISTIGES EIGENTUM, DATEIEN UND DATEN UND AUTORISIERUNGEN
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Wenn Sie Nabook nutzen und Ihre persönlichen Rezepte hochladen und in
      verschiedenen Formen personalisieren, stellen Sie uns Dateien (wie zum
      Beispiel persönliche Kochzeiten oder eigene Zutaten), Inhalte (zum
      Beispiel Beschreibungen, Arbeitsschritte oder Fotografien) und Weiteres
      (im Folgenden definiert als: "Ihr Inhalt") zur Verfügung. Ihr Inhalt ist
      und bleibt Ihr Eigentum. Die Geschäfts- und Nutzungsbedingungen gewähren
      Lainox - Ali Group S.r.l. mit Alleingesellschafter keinerlei rechtlichen
      Anspruch auf Ihren Inhalt, unter Ausnahme der eingeschränkten Rechte, die
      Lainox - Ali Group S.r.l. mit Alleingesellschafter erlauben, seine Dienste
      anzubieten. Lainox - Ali Group S.r.l. mit Alleingesellschafter ist und
      bleibt dazu verpflichtet, Ihren Inhalt vor nicht autorisierten Zugriffen
      zu schützen.<br />
      Lainox - Ali Group S.r.l. mit Alleingesellschafter braucht Ihre Erlaubnis,
      um Vorgänge wie das Hosting der Dateien, das Erstellen eines Backups, das
      Synchronisieren Ihres Inhalts mit Geräten und das Teilen des Inhalts, wenn
      dies erforderlich ist, vornehmen zu können. Über die Dienste von Nabook
      können außerdem Funktionen wie das Verkleinern von Fotos, die Vorschau von
      Dokumenten, das Hinzufügen von Kommentaren und einfache Modalitäten für
      Bestellungen, Änderungen, das Teilen und Suchen Ihres Inhalts bereit
      gestellt werden. Diese und weitere Funktionen könnten den Zugriff, die
      Archivierung und das Scannen des gesamten oder teilweisen Inhalts durch
      das System erforderlich machen. Sie erteilen Lainox - Ali Group S.r.l. mit
      Alleingesellschafter die Erlaubnis, diese Vorgänge auszuführen, und diese
      Erlaubnis erstreckt sich auch auf Franchisenehmer und vertrauenswürdige
      Dritte, mit denen Lainox - Ali Group S.r.l. mit Alleingesellschafter
      zusammenarbeitet.<br />
      Auf der Basis dieser Vereinbarung über die Nutzungsbedingungen von Nabook
      werden die Rechte auf Ihre nicht personenbezogenen Nutzungsinformationen
      und/oder auf die in jeder Form an Lainox - Ali Group S.r.l. mit
      Alleingesellschafter zur Bewertung oder Veröffentlichung geschickten Daten
      an Lainox - Ali Group S.r.l. mit Alleingesellschafter kostenlos,
      umfassend, unwiderruflich, nicht exklusiv auf unbestimmte Zeit eingeräumt,
      um sie auf jedem Träger zu nutzen, sie zu verarbeiten, zu reproduzieren,
      zu veröffentlichen, zu übersetzen und zu verbreiten. Sie gewähren uns
      außerdem das Recht, diese Rechte unsererseits an Dritte weiterzugeben.
      Lainox - Ali Group S.r.l. mit Alleingesellschafter behält sich das Recht
      vor, das auf Nabook Gesendete oder Veröffentlichte oder das, was von
      unseren Servern aufgenommen oder gespeichert wird, ganz oder teilweise zu
      verändern oder zu löschen. Lainox - Ali Group S.r.l. mit
      Alleingesellschafter ist nicht verpflichtet, das, was Sie auf Nabook
      senden oder veröffentlichen und was auf keinen Fall rechtswidrig oder
      unzulässig sein oder die Rechte anderer verletzen darf, zu überwachen oder
      zu kontrollieren.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COPYRIGHT
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. mit Alleingesellschafter achtet das geistige
      Eigentum Anderer und bittet Sie, dies genauso zu tun. Lainox - Ali Group
      S.r.l. mit Alleingesellschafter reagiert auf Meldungen mutmaßlicher
      Verletzungen des Urheberrechts, wenn sie den gesetzlichen Bestimmungen
      entsprechen und wenn sie uns im Rahmen der Vorschriften zum Urheberrecht
      mitgeteilt werden. Wir behalten uns das Recht vor, Inhalte, die
      mutmaßliche Verletzungen darstellen, zu löschen oder zu deaktivieren und
      Konten, auf die die Verletzungen zurückzuführen sind, aufzulösen.<br />
      Jedes Recht auf Inhalte (beispielsweise Texte, Bilder, graphische Layouts,
      Logos, Aufbau der Seiten, Architektur der Website, textliche Inhalte usw.)
      ist im Sinne der geltenden Rechtsvorschriften vorbehalten. Die Inhalte der
      Seiten der Website dürfen nicht ohne vorherige schriftliche Zustimmung -
      weder vollständig noch teilweise - auf jegliche Weise kopiert,
      reproduziert, übertragen, hochgeladen, veröffentlicht oder verbreitet
      werden. Davon unbeschadet bleibt die Möglichkeit, sie im eigenen Computer
      abzuspeichern oder Auszüge der Seiten dieser Website zu drucken. Dies gilt
      vor allem für die Dienste, die diese Website anbietet, wie zum Beispiel
      Menüs, Food Costs und Einkaufslisten.<br />
      Alle Marken und Logos, die der Lainox - Ali Group S.r.l. mit
      Alleingesellschafter gehören und auf den Seiten der Website angezeigt
      werden, sind ausschließliches Eigentum der Lainox - Ali Group S.r.l. mit
      Alleingesellschafter. Jegliche Nutzung und/oder Reproduktion dieser Marken
      und Logos zu jeglichem Zwecken oder über jegliche Mittel ist ausdrücklich
      und strengstens untersagt und wird entsprechend der geltenden Gesetze
      verfolgt. Eventuell auf den Seiten angezeigte Marken und Logos von Dritten
      sind ausschließliches Eigentum ihrer Inhaber, die die Lainox - Ali Group
      S.r.l. mit Alleingesellschafter zu deren Nutzung befugt haben: Jegliche
      Verwendung und/oder Reproduktion ist ebenfalls strengstens untersagt.<br />
      <strong
        >Diese Nutzungsbedingungen, die Datenschutzerklärung und die
        Cookie-Erklärung sind Grundlage der Vereinbarung zwischen Ihnen und
        Lainox - Ali Group S.r.l. mit Alleingesellschafter in Bezug auf die
        Nutzung von Nabook. Die eventuelle Nichteinhaltung dieser Bestimmungen
        wird, vorbehaltlich ausdrücklicher schriftlicher Genehmigung, von den
        zuständigen Zivil- und Strafgerichten verfolgt.</strong
      >
      <br /><br />
      Vittorio Veneto, 25/05/18
    </p>
  </div>

  <div *ngSwitchDefault>
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      nabookplus.cloud – WEB POLICY AND PRIVACY POLICY
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. - sole proprietorship would like to thank you
      for visiting its website and taking an interest in its products. We give
      great importance to the trust you place in us and would, therefore, like
      to share with you the website functions and management used to process
      your personal data, which we collect when you register on the Nabook
      portal.
      <br />
      Before using Nabook, you must register and create an account. In
      particular, during registration, we request confirmation that you have
      read, understood and given your express consent to the privacy policy,
      without which you cannot use the Nabook portal. The data collected will
      never be sold or passed on to third parties. The processing of your data
      is always based on the principles of legality and correctness, in
      compliance with the applicable laws in force. The policy in particular is
      provided under articles 13 and 14 of the GDPR - Regulation (EU) 2016/679
      to anyone who interacts with the web services accessible on-line through
      the pages on this website. The Nabook website uses cookies.
      <br />
      This privacy policy applies solely to the online activities of this
      website and to visitors/users of the site.
      <br />
      It does not apply to any information collected through channels other than
      this website.
      <br />
      The purpose of the privacy statement is to provide maximum transparency
      regarding the information that the site collects and how it is used.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      PRIVACY POLICY ON DATA PROCESSING
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      The processing connected to the web services of this site takes place at
      the offices of Lainox - Ali Group S.r.l. - sole proprietorship and is only
      managed by staff who have been given the specific task of processing the
      data and by anyone in charge of maintenance operations.
      <br />
      To prevent the loss, as well as the illegal or incorrect use of the data,
      specific security measures have been implemented to guarantee
      confidentiality and to ensure that third parties or other unauthorised
      parties are unable to access the data, in accordance with legal
      obligations.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      DATA CONTROLLER AND DATA PROCESSOR
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      The Data Controller and Data Processor are Lainox - Ali Group S.r.l. -
      sole proprietorship, Via Schiaparelli, 15, 31029 – Vittorio Veneto (TV),
      Italy.
      <br />
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      TYPE OF DATA PROCESSED<br />Data collected and purposes
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      During the normal course of operation, servers and IT systems used to
      operate the website acquire some personal data whose transmission is
      implicit in Internet communication protocols usage. Such information is
      not collected to associate it with identified data subjects, however, due
      to its nature, it might allow user identification after being processed
      and matched with data held by third parties.
      <br />
      This category of data includes IP addresses or domain names of computers
      used by users who connect to the site, URI addresses (Uniform Resource
      Identifier) of requested resources, the time of the request, the method
      utilised to submit the request to the server, the size of the file
      obtained in response, the numerical code indicating the status of the
      response from the server (successful, error, etc..) and other parameters
      related to the operating system and the users.
      <br />
      This data is only used to verify the correct functioning of the website.
      The data could be used by relevant authorities to determine responsibility
      in the event of cyber crimes to the detriment of the website.
    </p>
    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Data provided voluntarily by the user
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. - sole proprietorship processes and uses
      personal information only if provided in an optional, explicit or
      voluntary manner and only if legally allowed. Data collection is mandatory
      to allow access to the Nabook service, therefore, you must register and
      create an account in order to use the Nabook website. As part of the
      registration process, your email address, name, surname and telephone
      number will be collected; this data will not be sold or passed on to third
      parties. All the information (e.g. the company name, oven brand) which may
      not be sufficient to determine your identity can be collected, but is not
      considered personal information.
      <br />
      The data collected by the site during its operation are used exclusively
      for the purposes indicated above and kept for the time strictly necessary
      to carry out the specified activities.
      <br />
      In any case, personal data collected from the site will never be provided
      to third parties, except for the functional purposes of processing such
      data, unless upon legitimate request by the judicial authorities and only
      in the cases provided for by law.
      <br />
      The data may, however, be provided to third parties if this is necessary
      to deliver a specific service requested by the user, or to carry out
      security checks or optimise the site.
      <br />
      The data used for the purposes of site security (blocking attempts to
      damage the site) are stored for seven working days.
      <br />
      To manage the registration process, the website uses the double opt-in
      procedure, aimed at checking that the person requesting the subscription
      to the website is the actual owner of the email address and at providing a
      further express and explicit consent to the acceptance of the conditions
      for using the service. This means that to complete your registration, you
      must confirm it by clicking on the link that will be sent to the email
      address provided. If you do not send the confirmation message by clicking
      on the link within a certain time, your registration request will be
      automatically eliminated from the database. The data provided by you may
      be collected and used for marketing purposes only if you have given
      explicit authorisation for such use.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      International transfer of data.
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Your personal data are processed within the European Union or at most in
      countries that afford the same level of personal data protection as
      guaranteed by the relevant EU regulation.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Place of processing
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      The data collected by the site are processed at the headquarters of the
      data controller, and at the web hosting data centre.
      <br />
      The web hosting service is located in the European Economic Area and acts
      in accordance with European standards.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COOKIES
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      For information on the use of cookies on this website, please refer to our
      full policy – Cookie Policy.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      Existence of an automated decision-making process, including profiling
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. - sole proprietorship, does not adopt any
      automated decision-making process, including profiling, as referred to in
      article 22, paragraphs 1 and 4, of Regulation (EU) no. 679/2016.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      RIGHTS OF DATA SUBJECTS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Pursuant to articles 15 to 22 of Regulation (EU) no.<br />
      2016/679, you may at any time exercise the right to:<br />
      a) obtain confirmation as to whether or not your personal data are being
      processed;<br />
      b) obtain information on the purposes of the processing, the categories of
      personal data concerned, the recipients or categories of recipient to whom
      the personal data have been or will be disclosed and, where possible, the
      envisaged period for which the personal data will be stored;<br />
      c) obtain rectification or erasure of your personal data;<br />
      d) obtain restriction of processing;<br />
      e) obtain data portability, i.e. to receive the personal data which you
      have provided to a controller, in a structured, commonly used and
      machine-readable format and have the right to transmit those data to
      another controller without hindrance;<br />
      f) object at any time to processing of personal data, including data
      processed for direct marketing purposes;<br />
      g) oppose a decision based solely on automated processing, including
      profiling;<br />
      h) request from the controller access to and rectification or erasure of
      personal data or restriction of processing concerning the data subject or
      object to processing as well as the right to data portability;<br />
      i) withdraw consent at any time, without affecting the lawfulness of
      processing based on consent before its withdrawal;<br />
      j) lodge a complaint with a supervisory authority.<br />
      The exercising of such rights may, in any case, be delayed, restricted or
      denied, with justification communicated without delay to the data subject,
      as long as this constitutes a necessary and proportionate measure, taking
      into account the fundamental rights and legitimate interests of the data
      subject, in order to safeguard the interests referred to in paragraph 1,
      letters a), b), d) and e).<br />
      In such cases, the rights of the data subject may also be exercised
      through the Data Protection Authority; <br />
      Your requests should be addressed to Lainox - Ali Group S.r.l. - sole
      proprietorship, Via Schiaparelli, 15, 31029 – Vittorio Veneto (TV), Italy,
      to the phone number +39 0438 9110 or via e-mail to the address
      privacy.cr&#64;aligroup.it
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LIMITATIONS OF LIABILITY
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. - sole proprietorship is solely responsible for
      the Nabook platform regarding the relationship with you. The technical
      aspects of the Nabook website may be managed, on behalf of Lainox - Ali
      Group S.r.l. - sole proprietorship, by a third-party vendor, which stores
      all the data acquired as part of the process of managing Nabook. Data is
      stored in servers, physically located in the United Kingdom. Nabook is
      only accessible via the Internet: Lainox - Ali Group S.r.l. - sole
      proprietorship is in no way responsible for the Internet connection or the
      possible connection of the appliances. Lainox - Ali Group S.r.l. - sole
      proprietorship undertakes to ensure continuity of the service and to
      minimise any disruption caused by technical problems. However, it cannot
      guarantee that the service is or will remain available, that it is not
      subject to interruptions or malfunctions, or that the data and content of
      the site are maintained over time. Consequently, Lainox - Ali Group S.r.l.
      - sole proprietorship assumes no responsibility for damage of any kind
      that may arise from the use of the site or the inability to access it. It
      is also not liable for any malfunctions in links to external sites that
      may be connected to this site.<br />
      <strong
        >Lainox - Ali Group S.r.l. - sole proprietorship is not liable for any
        damage caused by the use of content (information, software, etc.) on
        this site. In particular, Lainox - Ali Group S.r.l. - sole
        proprietorship assumes no responsibility for the use of ingredients
        and/or products, present in recipes or other data in Nabook, by persons
        who are allergic or have adverse reactions to them.</strong
      >
      Indeed, the information on the individual ingredients is intended for
      information purposes only, for which you are fully responsibility since
      you can change any part of the ingredients cards available. In
      particular:<br />
      -the calories, which are for information purposes only, are always
      expressed in kcal and refer to 100 grams of ingredient, to a portion where
      indicated, unless otherwise indicated;<br />
      -the prices, which are for information purposes only, refer to a unit of
      the main measurement unit defined for the individual ingredient, unless
      otherwise indicated;<br />
      - the allergens, which are for information purposes only, indicated in the
      ingredients card are those commonly and potentially present in the type of
      ingredient.<br />
      Lainox - Ali Group S.r.l. - sole proprietorship reserves the right to make
      changes to the website at any time without notice.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      LINKS TO NABOOK
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Any link to this site must be authorised beforehand by Lainox - Ali Group
      S.r.l. - sole proprietorship. So-called deep linking is forbidden, i.e.
      the non-transparent use of this site, even in part, on third-party sites.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      NABOOK WEBSITE USER LICENSE
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. - sole proprietorship is pleased to grant you a
      non-exclusive and non-transferable license to use the Nabook portal, which
      is currently free. We reserve the right to charge a fee for the use of
      Nabook in the future and to cancel the current free use. Under no
      circumstances is compensation permitted for possible future fees with
      other complaints, nor the right to withholding, unless such request or
      right has been acknowledged in writing.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      CONTRACTUAL TERMS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      This agreement for the terms and conditions of using Nabook is valid
      indefinitely. However, it may be terminated at will by either party with a
      four-week notice period after communicating the intent to terminate the
      agreement. Lainox - Ali Group S.r.l. - sole proprietorship will, then, be
      allowed to deny access to Nabook and will have no obligation to maintain
      the information and/or data specifically present in your account.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      INTELLECTUAL PROPERTY, FILES, DATA AND PERMISSIONS
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      When you use Nabook, by uploading personal recipes and customising it in
      various forms, you provide us with files (e.g. customised cooking cycles
      or customised ingredients), content (e.g. descriptions, methods or
      photographs) and other information (hereinafter referred to as “your
      content"). Your content is and will remain your property. These terms do
      not grant Lainox - Ali Group S.r.l. - sole proprietorship any right on
      your content, with the exception of limited rights that allow Lainox - Ali
      Group S.r.l. - sole proprietorship to provide services. Lainox - Ali Group
      S.r.l. - sole proprietorship is and remains responsible for protecting
      your content from unauthorised access. Lainox - Ali Group S.r.l. - sole
      proprietorship requires your permission to perform operations, such as
      hosting files, making backups, synchronising your content with the devices
      and sharing them when required to do so. The services of Nabook may also
      offer features, such as thumbnails of photos, previews of documents,
      adding comments and simple ways to order, edit, share and search for your
      content. These and other features may require access, archiving and
      scanning of all or part of your content by the system. You grant Lainox -
      Ali Group S.r.l. - sole proprietorship permission to perform these
      operations and this permission extends to any affiliates and trusted third
      parties with whom Lainox - Ali Group S.r.l. - sole proprietorship works.
      Under this agreement for the terms and conditions of using Nabook, the
      rights on your non-personal information and/or data in any form sent to
      Lainox - Ali Group S.r.l. - sole proprietorship for assessment or
      publication are indefinitely granted to Lainox - Ali Group S.r.l. - sole
      proprietorship in a free, comprehensive, irrevocable, and non-exclusive
      form to use on any support, or to process, reproduce, publish, translate
      and distribute. You also grant us, in turn, the rights to transmit these
      rights to third parties. Lainox - Ali Group S.r.l. - sole proprietorship
      reserves the right to modify or eliminate, even only partially, the
      information transmitted or posted in Nabook, or hosted or stored on our
      servers. Lainox - Ali Group S.r.l. - sole proprietorship has no obligation
      to monitor or oversee the information transmitted and published by you on
      Nabook, which, under no circumstances, may be illegal or unlawful and must
      not violate the rights of others.
    </p>

    <p
      style="
        margin-bottom: 0px;
        text-align: justify;
        white-space: normal;
        font-weight: 700;
        font-size: 14pt;
      "
    >
      COPYRIGHT
    </p>
    <p
      style="margin-top: 0px; font-size: 14pt; font-family: Calibri, san-serif"
    >
      Lainox - Ali Group S.r.l. - sole proprietorship respects the intellectual
      property of others, and we ask you to do the same. Lainox - Ali Group
      S.r.l. - sole proprietorship responds to notifications related to alleged
      infringements of copyright if they comply with the law and if they are
      communicated through the rules on copyright. We reserve the right to
      delete or disable content representing alleged violations and to delete
      any accounts that violate the copyright. All right to the contents (by way
      of example, texts, images, graphic layouts, logos, page construction, site
      architecture, textual content, etc.) is reserved pursuant to current
      legislation. The content of the pages of the site cannot be - either
      totally or in part - copied, reproduced, transferred, loaded, published or
      distributed in any way without prior written consent, other than for
      storage on your own computer or for printing extracts from the pages of
      this site, in particular for the services that the site itself provides
      such as, purely by way of example, the menus, food costs and shopping
      lists. All the brands and logos belonging to Lainox - Ali Group S.r.l. -
      sole proprietorship displayed on the web pages are the exclusive property
      of Lainox - Ali Group S.r.l. - sole proprietorship. Any use and/or
      reproduction of them for any reason or means is expressly and strictly
      prohibited and will be pursued by the applicable laws. Any brands or logos
      belonging to third parties displayed on the website are exclusive property
      of the owners who have authorised their use by Lainox - Ali Group S.r.l. -
      sole proprietorship. Their use and/or reproduction is also strictly
      prohibited. These terms and conditions of use, the data processing policy
      and the cookies policy constitute the agreement between you and Lainox -
      Ali Group S.r.l. - sole proprietorship with regard to the use of Nabook.
      Failure to observe these provisions, without express written permission,
      will result in prosecution before the competent legal, civil and criminal
      courts.
      <br /><br />
      Vittorio Veneto, 25/05/18
    </p>
  </div>
</ng-container>
