import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DictionaryService } from '../core/dictionary.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  language: string = 'en';

  constructor(
    private ds: DictionaryService
  ) { 
    this.ds.lang$.pipe(
      tap((res) => this.language = res)
    ).subscribe()
  }

  ngOnInit(): void {
  }

}
