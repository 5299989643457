import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
  countryUserDetailList,
  provinceUserDetailList
} from 'src/app/core/utils';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {
  @Input() controlValue: UntypedFormGroup;

  countryItems = countryUserDetailList;
  districtItems = provinceUserDetailList;

  constructor() {}

  ngOnInit(): void {
    if ( !this.isItaly(this.controlValue.controls['country'].value) ) {
      this.controlValue.controls['province'].disable();
    }

    this.controlValue.controls['country'].valueChanges.subscribe((v) => {
      if (this.isItaly(v)) {
        this.controlValue.controls['province'].enable();
      } else  {
        this.controlValue.controls['province'].reset();
        this.controlValue.controls['province'].disable();
      }
    });
  }

  private isItaly(v: string) {
    return v === 'IT';
  }

}
