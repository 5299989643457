import { AuthService } from './core/auth.service';
import { MykeycloakService } from './core/mykeycloak.service';
import { environment } from '../environments/environment';

const realmName = environment.keycloack.realm;
const clientId = environment.keycloack.clientId;
const baseUrl = environment.keycloack.baseUrl;

export function keycloakInit(
  kc: MykeycloakService,
  authService: AuthService
): Promise<boolean | void> {
  return kc
    .init({
      config: {
        url: baseUrl,
        realm: realmName,
        clientId: clientId
      },
      initOptions: {
        onLoad: 'login-required'
      },
      loadUserProfileAtStartUp: true
    })
    .then((s) => {
      authService.evaluate();
    });
}
