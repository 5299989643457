<h1>{{ 'NAVIGATION.ACCESSORIES_ARCHIVE' | translate }}</h1>
<h2>
  <span> {{ 'NAVIGATION.ACCESSORIES_ARCHIVE' | translate }}</span>
  <div class="button-box">
    <button class="button-primary" (click)="createAccessory()">
      <i class="fa fa-plus"></i>
      {{ 'ACCESSORIES.ADD_NEW_ACCESSORY' | translate }}
    </button>
  </div>
</h2>

<div class="row">
  <div class="filter-side-box col-12 col-lg-3">
    <label class="custom-label w-100">
      {{ 'ACCESSORIES.FILTERS.NAME' | translate }}
      <app-data-source-search-field
        field="name/value"
        [fieldControl]="filterName"
        [dataSource]="dataSource"
        [applyOnChanges]="false"
      ></app-data-source-search-field>
      <!-- field="name/it" -->
    </label>
    <div class="filter-button-box">
      <button class="button-primary" (click)="applyFilter()">
        <i class="fa fa-fw fa-search"></i>{{ 'GLOBAL.FIND' | translate }}
      </button>
      <button class="button-secondary" (click)="cleanFilter()">
        <i class="fa fa-fw fa-undo"></i>{{ 'GLOBAL.CLEAN' | translate }}
      </button>
    </div>
  </div>
  <div class="col-12 col-lg-9">
    <app-datatable-paginated
      [dataSource]="dataSource"
      [columns]="columns"
      [className]="'fix-height'"
    ></app-datatable-paginated>
  </div>
</div>

<ng-template #actionTpl let-row="row" let-rowIndex="rowIndex">
  <i
    (click)="editAccessory(row, rowIndex)"
    class="table-icons fa fa-fw fa-pen"
  ></i>
  <i
    (click)="deleteAccessory(row, rowIndex)"
    class="table-icons fa fa-fw fa-times"
  ></i>
</ng-template>

<ng-template #imageTpl let-row="row">
  <ng-container *ngIf="row.images && row.images.length > 0">
    <img
      [src]="row.images[0].url"
      class="img-thumbnail rounded w-50 my-3"
      [alt]="row.name"
      [title]="row.name"
    />
  </ng-container>
</ng-template>
