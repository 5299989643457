<h2 class="mt-3">
  <span>
    {{ 'MY_DISPLAY.MY_DEVICES' | translate }}
  </span>

  <div class="button-box">
    <button class="button-primary" (click)="pairDevice()">
      <i class="fa fa-plus"></i> {{ 'GLOBAL.NEW_DEVICE' | translate }}
    </button>
  </div>
</h2>
<div id="devices-list">
  <ng-container *ngIf="deviceList$ | async; let deviceList">
    <ng-container *ngFor="let device of deviceList">
      <app-device-card
        class="device-card"
        [device]="device"
        [kcUser]="kcUser"
        (update)="refreshDeviceList()"
      ></app-device-card>
    </ng-container>
  </ng-container>
</div>
<div class="text-right my-3">
  <a [routerLink]="['/', 'devices']" class="link-primary">
    {{ 'DEVICES.VIEW_ALL' | translate }}
    <i class="fa fa-fw fa-angle-right"></i>
  </a>
</div>
