<footer>
  <div class="row">
    <div id="footer-info" class="footer-info col-12 col-lg-4 mt-3">
      <a href="https://lainox.it/it"
        ><img src="assets/images/footer_logo.png" alt="Lainox"
      /></a>
      <p>
        <span [innerHtml]="'FOOTER.MY_NABOO' | translate"></span>
        <br />
        <a routerLink="/cookies" target="_blank">Cookies</a> |
        <a routerLink="/privacy" target="_blank">Privacy Policy</a>
      </p>
    </div>
    <div id="footer-social" class="footer-social col-12 col-md-6 col-lg-4 mt-3">
      <a
        href="https://www.linkedin.com/company/lainox-ali-spa"
        target="_blank"
        class="social-pill"
        ><i class="fab fa-fw fa-linkedin"></i
      ></a>
      <a
        href="https://www.facebook.com/Lainox.Ali"
        target="_blank"
        class="social-pill"
        ><i class="fab fa-fw fa-facebook"></i
      ></a>
  
      <a
        href="https://www.youtube.com/channel/UCwEYkqOir8xbojEPR9XRyBw"
        target="_blank"
        class="social-pill"
        ><i class="fab fa-fw fa-youtube"></i
      ></a>
      <a
        href="https://www.instagram.com/lainoxali/"
        target="_blank"
        class="social-pill"
        ><i class="fab fa-fw fa-instagram"></i
      ></a>
    </div>
    <div id="footer-image" class="footer-image col-12 col-md-6 col-lg-4 mt-3">
      <img src="assets/images/ali-logo.png" alt="Ali" />
    </div>
  </div>
</footer>
