import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from 'src/app/core/dictionary.service';

@Component({
  selector: 'app-sector',
  templateUrl: './sector.component.html',
  styleUrls: ['./sector.component.scss']
})
export class SectorComponent implements OnInit {
  @Input() controlValue: UntypedFormGroup;

  sectorItems: any[] = [];

  constructor(private ds: DictionaryService, private ts: TranslateService) {
    this.ds.lang$.subscribe((lang) => this.getSectors(lang));
  }

  ngOnInit(): void {}

  private getSectors(lang: string) {
    this.sectorItems = [
      {
        key: 'Gruppo di acquisto',
        value: this.ts.instant('USER_DETAIL.SECTOR.PURCHASING_GROUP')
      },
      {
        key: 'Ristorante',
        value: this.ts.instant('USER_DETAIL.SECTOR.RESTAURANT')
      },
      { key: 'Hotel', value: this.ts.instant('USER_DETAIL.SECTOR.HOTEL') },
      {
        key: 'Supermarket e catene',
        value: this.ts.instant('USER_DETAIL.SECTOR.SUPERMARKET')
      },
      {
        key: 'Opinion Leader',
        value: this.ts.instant('USER_DETAIL.SECTOR.OPINION_LEADER')
      },
      {
        key: 'Macelleria',
        value: this.ts.instant('USER_DETAIL.SECTOR.BUTCHER_SHOP')
      },
      {
        key: 'Pasticceria/Panetteria',
        value: this.ts.instant('USER_DETAIL.SECTOR.PASTRY_SHOP')
      },
      {
        key: 'Gastronomie',
        value: this.ts.instant('USER_DETAIL.SECTOR.GASTRONOMY')
      },
      {
        key: 'Ospedale',
        value: this.ts.instant('USER_DETAIL.SECTOR.HOSPITAL')
      },
      {
        key: 'Penitenziario',
        value: this.ts.instant('USER_DETAIL.SECTOR.PRISON')
      },
      {
        key: 'Casa di riposo',
        value: this.ts.instant('USER_DETAIL.SECTOR.RETIREMENT_HOME')
      },
      {
        key: 'Scuola Catering',
        value: this.ts.instant('USER_DETAIL.SECTOR.CATERING_SCHOOL')
      },
      {
        key: 'Aziende',
        value: this.ts.instant('USER_DETAIL.SECTOR.COMPANIES')
      },
      { key: 'Mense', value: this.ts.instant('USER_DETAIL.SECTOR.CANTEENS') },
      { key: 'Altro', value: this.ts.instant('USER_DETAIL.SECTOR.OTHER') }
    ];
  }
}
