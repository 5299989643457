import { Injectable } from '@angular/core';

import { EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import {
  DashboardBoDtoNBK,
  DashboardService,
  UserKeycloakDtoNBK,
  UserService,
} from '../api/nbk';

interface IDashboardResolverData {
  counters: DashboardBoDtoNBK;
  loggedUser: UserKeycloakDtoNBK;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardResolver  {

  unsubscribe$ = new Subject();
  
  constructor(private dashboardService: DashboardService, private userService : UserService) {}

  resolve(): Observable<IDashboardResolverData> {

    return forkJoin([
          this.dashboardService.getDashboardData(),
          this.userService.getLoggedUser()
        ]).pipe(
          map((resp) => {
            return {
              counters: resp[0],
              loggedUser: resp[1]
            };
          }),
          take(1),
          catchError(() => {
            return EMPTY;
          })
        )
  }
}

