<div id="language-box">
  <i class="fa fa-fw fa-globe-americas"></i>
  <select
    name=""
    id=""
    (change)="onLanguageChange($event)"
    [formControl]="language"
  >
    <option *ngFor="let l of languages" [value]="l.lang">
      {{ l.flag }} {{ l.label | translate }}
    </option>
  </select>
</div>
