<footer>
  <div id="footer-info">
    <a href="https://lainox.it/it"
      ><img src="assets/images/footer_logo.png" alt="Lainox"
    /></a>
    <p>
      <span [innerHtml]="'FOOTER.MY_NABOO' | translate"></span>
      <br />
      <a routerLink="/cookies" target="_blank">Cookies</a> |
      <a routerLink="/privacy" target="_blank">Privacy Policy</a>
    </p>
  </div>
  <div id="footer-social">
    <a
      href="https://www.linkedin.com/company/lainox-ali-spa"
      target="_blank"
      class="social-pill"
      ><i class="fab fa-fw fa-linkedin"></i
    ></a>
    <a
      href="https://www.facebook.com/Lainox.Ali"
      target="_blank"
      class="social-pill"
      ><i class="fab fa-fw fa-facebook"></i
    ></a>
    <!-- <a href="https://twitter.com/LainoxAli" target="_blank" class="social-pill"
      ><i class="fab fa-fw fa-twitter"></i
    ></a> -->
    <a
      href="https://www.youtube.com/channel/UCwEYkqOir8xbojEPR9XRyBw"
      target="_blank"
      class="social-pill"
      ><i class="fab fa-fw fa-youtube"></i
    ></a>
    <a
      href="https://www.instagram.com/lainoxali/"
      target="_blank"
      class="social-pill"
      ><i class="fab fa-fw fa-instagram"></i
    ></a>
  </div>
  <img src="assets/images/ali-logo.png" alt="Ali" />
</footer>
