import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { UserKeycloakDtoNBK, UserService, UserSettingsDtoNBK } from 'src/app/api/nbk';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { infoDevice, InfoDeviceEnum, INFO_DEVICE_STORAGE_KEY, showDeviceInfo } from 'src/app/core/device.utils';
import { indicatorItemList } from 'src/app/shared/double-indicator-selector/double-indicator-selector.model';
import { SettingsPinModal } from 'src/app/shared/modals/settings-pin-modal/settings-pin-modal.component';

@Component({
  selector: 'app-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.scss']
})
export class UserPreferenceComponent implements OnInit, OnDestroy {
  temperatureItems: indicatorItemList = [
    { label: 'F\u00B0', value: 'F' },
    { label: 'C\u00B0', value: 'C' }
  ];

  measurementItems: indicatorItemList = [
    { label: 'I', value: 'I' },
    { label: 'M', value: 'M' }
  ];

  kcUser: UserKeycloakDtoNBK;
  isFunctionalityLock = true;
  infoDevice = infoDevice;

  nabookIsMasterControl: UntypedFormControl = new UntypedFormControl(false, []);
  multidisplayControl: UntypedFormControl = new UntypedFormControl(false, []);
  temperatureControl: UntypedFormControl = new UntypedFormControl('F', []);
  measurementControl: UntypedFormControl = new UntypedFormControl('I', []);

  timerInSeconds: UntypedFormControl = new UntypedFormControl(false, []);

  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private ts: TranslateService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.toggleMultidisplayContol();

    this.multidisplayControl.valueChanges.subscribe((v) => {
      if ( v ) {
        this.nabookIsMasterControl.setValue(true);
        this.nabookIsMasterControl.disable();
      } else {
        this.nabookIsMasterControl.enable();
      }
    });

    this.userService
      .getLoggedUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.kcUser = res;
          this.temperatureControl.setValue(res.temperatureUnit || 'C');
          this.measurementControl.setValue(res.measureUnit || 'I');
          this.timerInSeconds.setValue(res.timerInSeconds || false);
          this.nabookIsMasterControl.setValue(res.nabookIsMaster || false);
          this.multidisplayControl.setValue(res.multiDisplay || false);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSavePreference() {
    this.isFunctionalityLock = true;
    this.toggleMultidisplayContol();

    const preferences : UserSettingsDtoNBK = {
      temperatureUnit: this.temperatureControl.value,
      measureUnit: this.measurementControl.value,
      timerInSeconds: this.timerInSeconds.value,
      nabookIsMaster: this.nabookIsMasterControl.value,
      multiDisplay: this.multidisplayControl.value
    };

    this.userService
      .updateUserSettings(preferences)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('USER_PREFERENCE.SAVED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
  }

  updateInfoDevice(e: any, key: InfoDeviceEnum) {
    const infoDeviceVisible = JSON.parse(
      localStorage.getItem(INFO_DEVICE_STORAGE_KEY)!
    );
    let newInfoDeviceVisible;
    if (e.target!.checked) {
      newInfoDeviceVisible = [...infoDeviceVisible, key];
    } else {
      newInfoDeviceVisible = infoDeviceVisible.filter((i: string) => i !== key);
    }
    localStorage.setItem(
      INFO_DEVICE_STORAGE_KEY,
      JSON.stringify(newInfoDeviceVisible)
    );
  }

  checkIfInfoDevice(key: InfoDeviceEnum) {
    return showDeviceInfo(key);
  }

  lockTooltip() {
    return this.ts.instant( this.isFunctionalityLock ? 'GLOBAL.UNLOCK' : 'GLOBAL.LOCK');
  }

  toggleFunctionality() {
    if ( this.isFunctionalityLock ) {
      this.handleSettingsPin();
    } else {
      this.isFunctionalityLock = true;
      this.toggleMultidisplayContol();
    }
  }

  private toggleMultidisplayContol() {
    this.isFunctionalityLock ? this.multidisplayControl.disable() : this.multidisplayControl.enable();
  }

  handleSettingsPin() {
    const modalRef = this.modalService.open(SettingsPinModal, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.currentPin = this.kcUser.settingsPin;
    modalRef.componentInstance.insertPin.subscribe((psw: string) => {
      this.userService
      .setSettingsPin(psw)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.kcUser = res;
          this.isFunctionalityLock = false;
          this.toggleMultidisplayContol();
        })
      ).subscribe();
    });
    modalRef.componentInstance.validPin.subscribe(() => {
      this.isFunctionalityLock = false;
      this.toggleMultidisplayContol();
    });
  }

}
