import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private ts: TranslateService,
    private route: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (
          new RegExp('/cloud/api/bo/myNaboo/[0-9]+?').test(req.url) &&
          req.method === 'PUT' &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.MYNABOO_DUPLICATE'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/recipe$').test(req.url) &&
          ( req.method === 'POST' || req.method === 'PUT') &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.DUPLICATE_RECIPE'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/ingredient').test(req.url) &&
          ( req.method === 'POST' || req.method === 'PUT') &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.DUPLICATE_INGREDIENT'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/recipe/markIcs$').test(req.url) &&
          req.method === 'PUT' &&
          error.status === 400
        ) {
          var ingredientError = JSON.parse(error.error).message.includes("ingredient");
          this.toastr.error(
            this.ts.instant(ingredientError ? 'ERROR.DUPLICATE_INGREDIENT' :'ERROR.DUPLICATE_RECIPE'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/ingredient/markIcs$').test(req.url) &&
          req.method === 'PUT' &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.DUPLICATE_INGREDIENT'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/device/matchDeviceToUser').test(req.url) &&
          req.method === 'PUT' &&
          error.status === 404
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.DEVICE_NOT_FOUND'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/recipe/[0-9]+?').test(req.url) &&
          req.method === 'DELETE' &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.RECIPE_ASSOCIATED'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/myNaboo/[0-9]+?').test(req.url) &&
          req.method === 'DELETE' &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.RECIPE_ASSOCIATED'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          ( new RegExp('/cloud/api/bo/device/[0-9]+?/params').test(req.url) || 
            new RegExp('/cloud/api/bo/device/[0-9]+?/settings').test(req.url) )
          && (req.method === 'GET' || req.method === 'POST') 
          && error.status === 504
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.NO_RESPONSE_FROM_DEVICE'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/accessory/[0-9]+?').test(req.url) &&
          req.method === 'DELETE' &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.ACCESSORY_ASSOCIATED'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/ingredient/[0-9]+?').test(req.url) &&
          req.method === 'DELETE' &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.INGREDIENT_ASSOCIATED'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/allergen/[0-9]+?').test(req.url) &&
          req.method === 'DELETE' &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.ALLERGEN_ASSOCIATED'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/user').test(req.url) &&
          req.method === 'POST' &&
          error.status === 409
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.DUPLICATE_USER'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else if (
          new RegExp('/cloud/api/bo/folder').test(req.url) &&
          error.status === 406 && error.error.code === 'BL-F0001'
        ) {
            this.toastr.error(
              this.ts.instant('ERROR.NOT_ML_RECIPE'),
              this.ts.instant('GLOBAL.ERROR')
            );
        } else if (
          new RegExp('/cloud/api/bo/folder').test(req.url) &&
          error.status === 406 && error.error.code === 'BL-F0002'
        ) {
            this.toastr.error(
              this.ts.instant('ERROR.ML_RECIPE_PREHEAT_BOUNDARY'),
              this.ts.instant('GLOBAL.ERROR')
            );
        } else if (
          new RegExp('/cloud/api/bo/accessory').test(req.url) &&
          ( req.method === 'POST' || req.method === 'PUT') &&
          error.status === 400
        ) {
          this.toastr.error(
            this.ts.instant('ERROR.DUPLICATE_ACCESSORY'),
            this.ts.instant('GLOBAL.ERROR')
          );
        } else {
          this.toastr.error(
            this.ts.instant('GLOBAL.HTTP_ERROR'),
            this.ts.instant('GLOBAL.ERROR')
          );
        }

        return throwError(error);
      })
    );
  }
}
