// store/sidebar/sidebar.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { byCodeSidebar, notByCodeSidebar } from './sidebar.actions'

export interface SidebarState {
  byCode: boolean;
}

export const initialState: SidebarState = {
    byCode: false,
};

export const sidebarReducer = createReducer(
  initialState,
  on(byCodeSidebar, state => ({ ...state, byCode: true })),
  on(notByCodeSidebar, state => ({ ...state, byCode: false }))
);
