import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  @Input() controlValue: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.controlValue.controls['notWorking'].valueChanges.subscribe((v) => {
      if (v) {
        this.controlValue.controls['name'].disable();
        this.controlValue.controls['vatNumber'].disable();
        this.controlValue.controls['phoneNumber'].disable();
        this.controlValue.controls['email'].disable();
      } else {
        this.controlValue.controls['name'].enable();
        this.controlValue.controls['vatNumber'].enable();
        this.controlValue.controls['phoneNumber'].enable();
        this.controlValue.controls['email'].enable();
      }
    });
  }
}
