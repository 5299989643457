<h2>
  <span> {{ 'NAVIGATION.MY_NABOO' | translate }}</span>

  <div class="button-box">
    <button class="button-primary" (click)="createNewRecipe()">
      <i class="fa fa-plus"></i> 
      {{ 'GLOBAL.NEW_RECIPE' | translate }}
    </button>
    <button class="button-primary" (click)="fileRecipe.click()">
      <i class="fa fa-file-upload"></i> 
      {{ 'GLOBAL.LOAD_RECIPE' | translate }}
    </button>
    <button class="button-primary" [routerLink]="['/', 'ingredients']">
      <i class="fas fa-pepper-hot"></i>
      {{ 'GLOBAL.INGREDIENTS_ARCHIVE' | translate }}
    </button>
    <button
      class="button-primary"
      [routerLink]="['/', 'accessories']"
      *ngIf="canHandle()"
    >
      <i class="fas fa-utensils"></i>
      {{ 'GLOBAL.ACCESSORIES_ARCHIVE' | translate }}
    </button>
    <button class="button-primary" [routerLink]="['/', 'my-naboo']">
      <i class="fa fa-search"></i> 
      {{ 'GLOBAL.FIND_RECIPE' | translate }}
    </button>
  </div>
</h2>

<div id="counters" class="counters row">
  <div class="col-12 col-md-6 col-lg-3">
    <app-counter
      [total]="counters.recipesMyNaboo"
      [text]="'DASHBOARD.COUNTERS.MY_NABOO' | translate"
      [routerLink]="'/my-naboo'"
    ></app-counter>
  </div>
  <div class="col-12 col-md-6 col-lg-3">
    <app-counter
      [total]="counters.recipesWithoutIngredients"
      [text]="'DASHBOARD.COUNTERS.NO_INGREDIENTS' | translate"
      [routerLink]="'/my-naboo'"
      [queryParams]="{ filter: 'noIngredients' }"
    ></app-counter>
  </div>
  <div class="col-12 col-md-6 col-lg-3">
    <app-counter
      [total]="counters.recipesWithoutCookingSteps"
      [text]="'DASHBOARD.COUNTERS.NO_COOKING_CYCLES' | translate"
      [routerLink]="'/my-naboo'"
      [queryParams]="{ filter: 'noCookingStep' }"
    ></app-counter>
  </div>
  <div class="col-12 col-md-6 col-lg-3">
    <app-counter
      [total]="counters.displayUsed"
      [text]="'DASHBOARD.COUNTERS.DISPLAY_USED' | translate"
      [routerLink]="'/my-display'"
    ></app-counter>
  </div>
</div>

<input
  #fileRecipe
  type="file"
  accept="zip"
  class="d-none"
  (change)="uploadNewRecipe($event)"
/>
