import { AuthService } from './core/auth.service';
import { DictionaryService } from './core/dictionary.service';
import { MykeycloakService } from './core/mykeycloak.service';
import { keycloakInit } from './keycloakInit';

function translateInit(ds: DictionaryService): Promise<void> {
  return ds.initLanguage();
}

export function appInit(
  ds: DictionaryService,
  kc: MykeycloakService,
  auth: AuthService
) {
  return () => keycloakInit(kc, auth).then(() => translateInit(ds));
}
