import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DictionaryService, TDirection } from '../core/dictionary.service';
import { NavigationService } from './navigation/navigation.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('main', { static: false }) main: ElementRef;
  @ViewChild('blockMenuResponsive', { static: false })
  blockMenuResponsive: ElementRef;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.ns.setCurrentWidth(window.innerWidth);
  }

  unsubscribe$: Subject<void> = new Subject();
  direction: Observable<TDirection>;

  constructor(
    private ns: NavigationService,
    private renderer: Renderer2,
    private ds: DictionaryService
  ) {}

  ngOnInit(): void {
    this.ns.lockLayout$.subscribe((state: boolean) => {
      state ? this.lock() : this.unlock();
    });

    this.direction = this.ds.themeDirection$.pipe(map((res) => res));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

  lock() {
    this.renderer.addClass(this.main.nativeElement, 'overflow-hidden');
    this.renderer.addClass(this.blockMenuResponsive.nativeElement, 'open');
  }

  unlock() {
    this.renderer.removeClass(this.main.nativeElement, 'overflow-hidden');
    this.renderer.removeClass(this.blockMenuResponsive.nativeElement, 'open');
  }

}
