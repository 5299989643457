<h1>{{ 'NAVIGATION.SETTINGS' | translate }}</h1>
<h2>
  {{ 'USER_PREFERENCE.METRICS' | translate }}
  <div class="button-box">
    <button class="button-primary" (click)="onSavePreference()">
      <i class="fa fa-save"></i>
      {{ 'USER_PREFERENCE.BUTTONS.SAVE' | translate }}
    </button>
  </div>
</h2>
<div class="content-box pb-5" id="settings-box">
  <div>
    <div class="row align-items-center">
      <div class="col-12">
        <ul class="info-list">
          <li>
            <app-double-indicator-selector
              [title]="'USER_PREFERENCE.TEMPERATURE' | translate"
              [items]="temperatureItems"
              [type]="'temperature'"
              [selectorControl]="temperatureControl"
            ></app-double-indicator-selector>
          </li>
          <!-- <li>
            <app-double-indicator-selector
              [title]="'USER_PREFERENCE.MEASURE' | translate"
              [items]="measurementItems"
              [type]="'measure-type'"
              [selectorControl]="measurementControl"
            ></app-double-indicator-selector>
          </li> -->
          <!-- <li>
            <label class="main-label fix-label">
              {{ 'USER_PREFERENCE.TIMER_RECIPES' | translate }}
            </label>
            <input type="checkbox" [formControl]="timerInSeconds" />
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
<h2>
  {{ 'USER_PREFERENCE.VIEW_DEVICE' | translate }}
</h2>
<div class="row pb-5">
  <ng-container *ngFor="let info of infoDevice">
    <div class="col-4">
      <label class="main-label">
        <input
          type="checkbox"
          class="me-3"
          (change)="updateInfoDevice($event, info.key)"
          [checked]="checkIfInfoDevice(info.key)"
        />
        {{ info.label | translate }}
      </label>
    </div>
  </ng-container>
</div>
<h2>
  {{ 'USER_PREFERENCE.OTHERS' | translate }}
</h2>
<div class="content-box pb-5" id="settings-box">
  <div>
    <div class="row align-items-center">
      <div class="col-12">
        <label class="main-label">
          <input
            type="checkbox"
            class="me-3"
            [formControl]="nabookIsMasterControl"
          />
          {{ 'USER_PREFERENCE.NABOOK_IS_MASTER' | translate }}
        </label>
      </div>
      <div class="col-12">
        <label class="main-label">
          <input
            type="checkbox"
            class="me-3"
            [formControl]="multidisplayControl"
          />
          {{ 'USER_PREFERENCE.ENABLE_MULTIDISPLAY' | translate }}
        </label>
        <button class="button-icon" style="margin-left: 3em; font-size: x-large;" 
          [tooltip]="lockTooltip()" 
          (click)="toggleFunctionality()">
          <i [ngClass]="isFunctionalityLock ? 'fa fa-lock' : 'fa fa-lock-open'"></i>
        </button>
      </div>
    </div>
  </div>
</div>
