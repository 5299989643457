import { Component, OnInit } from '@angular/core';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { take } from 'rxjs/operators';
import { MenuDtoNBK, MenuService } from 'src/app/api/nbk';

@Component({
  selector: 'app-dashboard-menus',
  templateUrl: './dashboard-menus.component.html',
  styleUrls: ['./dashboard-menus.component.scss']
})
export class DashboardMenusComponent implements OnInit {
  menus: ODataFiltrableDataSource<MenuDtoNBK> =
    new ODataFiltrableDataSource<MenuDtoNBK>();

  constructor(private menuService: MenuService) {}

  ngOnInit(): void {
    this.menus.fetchFunction = (top, skip, count, _orderBy, filter) => {
      return this.menuService
        .getAllMenu(
          sessionStorage.getItem('language')!,
          5,
          skip,
          count,
          _orderBy
        )
        .pipe(take(1));
    };
    this.menus.loadData();
  }
}
