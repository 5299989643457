<aside #aside>
  <div>
    <div
      class="close-menu"
      (click)="closeResponsiveMenu()"
    >
      <i class="fa fa-times"></i>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <img id="logo" src="assets/images/logo_plus.svg" alt="Logo Nabook" />
    </div>

    <app-user-box></app-user-box>

    <ul id="mainMenu">
      <ng-container *ngFor="let item of navigationItems">
        <li *ngIf="item.condition">
          <a
            [routerLink]="item.routeLink"
            [routerLinkActive]="'active'"
            href="javascript:void(0)"
          >
            <i class="fa fa-fw" [ngClass]="item.iconClass"></i>
            <span>{{ item.labelKey | translate }}</span>
          </a>
        </li>
      </ng-container>
      <li>
        <a (click)="logout()" href="javascript:void(0)"
          ><i class="fa fa-fw fa-sign-out-alt"></i>
          <span>{{ 'NAVIGATION.LOGOUT' | translate }}</span></a
        >
      </li>
    </ul>
  </div>

  <div class="pt-3">
    <app-language-box></app-language-box>
    <p id="app-version">{{ APP_VERSION }}</p>
  </div>

</aside>
